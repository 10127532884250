<template>
    <div class="v-main__wrap">
        <div style="background-color: #F9FAFB; height: 100%;">
            <v-card class="mx-auto" color="#F9FAFB" max-width="773" flat>
                <VSpacer class="mb-8"></VSpacer>
                <div class="header-wrap">
                    <div v-if="jwtData.logo==''" class="contents">
                        <h5 class="expire-content">{{ formattedTime }}</h5>
                    </div>
                    <div v-else class="contents">
                        <v-img max-width="150" max-height="45" :src="logo"></v-img>
                        <h5 class="expire-content">{{ formattedTime }}</h5>
                    </div>
                </div>
                <VSpacer class="mb-3"></VSpacer>
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel v-for="(space, index) in jwtData.space_info" :key="index">
                        <v-expansion-panel-header class="expansion-header">
                            <v-row no-gutters class="invoice-line-row">
                                <v-col cols="8" class="my-2 space-no">
                                    Space {{ space.Unit.number }}
                                </v-col>
                                <v-col cols="" class="align-right mt-5 space-no">
                                    {{ space | totalLease | formatMoney }}
                                </v-col>
                                <v-col cols="8" class="address-text">
                                    {{ space.Unit.label }}, {{ space.Unit.Address.address }}, {{ space.Unit.Address.city }},
                                    {{ space.Unit.Address.state }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div v-for="(invoice, index) in space.OpenInvoices" :key="index" class="sample">
                                <v-row no-gutters class="invoice-line-row">
                                    <v-col>
                                        <span class="caption">Invoice#{{ invoice.number }}</span>
                                    </v-col>
                                </v-row>
                                <div v-for="(line, index) in invoice.InvoiceLines" :key="index">
                                    <v-row class="invoice-line-row" no-gutters>
                                        <v-col style="flex: 1 0 50%"> {{ line.Product.name }} ({{
                                            line.start_date | formatLocalShortDate }}-{{ line.end_date | formatLocalShortDate }}
                                            )</v-col>
                                        <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                        <v-col style="flex: 0 1 0%; align-items: flex-end;" class="align-right">{{
                                            (line.qty * line.cost) | formatMoney }}</v-col>
                                    </v-row>
                                </div>
                                <v-row class="invoice-line-row" no-gutters>
                                    <v-col style="flex: 1 0 50%"><strong>Subtotal</strong></v-col>
                                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                    <v-col style="flex: 0 1 0%" class="align-right"><strong>{{ invoice.sub_total | formatMoney
                                    }}</strong></v-col>
                                </v-row>
                                <v-row class="invoice-line-row" no-gutters>
                                    <v-col style="flex: 1 0 50%">Discounts</v-col>
                                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                    <v-col style="flex: 0 1 0%" class="align-right">{{ invoice.discounts | formatMoney
                                    }}</v-col>
                                </v-row>
                                <v-row class="invoice-line-row" no-gutters>
                                    <v-col style="flex: 1 0 50%">Tax</v-col>
                                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                    <v-col style="flex: 0 1 0%" class="align-right">{{ invoice.total_tax | formatMoney
                                    }}</v-col>
                                </v-row>
                                <v-row
                                    class="invoice-line-row"
                                    no-gutters
                                    v-if="invoice.total_payments"
                                >
                                    <v-col style="flex: 1 0 50%">Previous Payments</v-col>
                                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                    <v-col style="flex: 0 1 0%" class="align-right"
                                    >({{ invoice.total_payments | formatMoney }})</v-col
                                    >
                                </v-row>
                                <v-row class="invoice-line-row" no-gutters>
                                    <v-col style="flex: 1 0 50%"><strong>Total</strong></v-col>
                                    <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                    <v-col style="flex: 0 1 0%" class="align-right"><strong>{{ invoice.balance | formatMoney
                                    }}</strong></v-col>
                                </v-row>
                                <v-spacer class="my-2">
                                    <v-divider></v-divider>
                                </v-spacer>
                            </div>
                            <v-row class="invoice-line-row" no-gutters>
                                <v-col style="flex: 1 0 50%"><strong>Total Balance Due: </strong></v-col>
                                <v-col style="flex: 0 1 0%">&nbsp;</v-col>
                                <v-col style="flex: 0 1 0%" class="align-right"><strong>{{ space | totalLease | formatMoney
                                }}</strong></v-col>
                            </v-row>
                        </v-expansion-panel-content>
                        <v-divider></v-divider>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-container class="black" style="height: 82px;">
                    <v-row no-gutters class="charge-block py-4 mx-4">
                        <p class="charge-total">Total To Be Charged</p>
                        <p class="amount">{{ totalAmount | formatMoney }}</p>
                    </v-row>
                </v-container>
                <VSpacer class="mb-8"></VSpacer>
                <v-card class>
                    <div v-if="errorMessage" id="error-message" class="error-message">
                        <div class="svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <mask id="mask0_1420_11813" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_1420_11813)">
                                <path d="M7.5 13H17.5V11H7.5V13ZM12.5 22C11.1167 22 9.81667 21.7375 8.6 21.2125C7.38333 20.6875 6.325 19.975 5.425 19.075C4.525 18.175 3.8125 17.1167 3.2875 15.9C2.7625 14.6833 2.5 13.3833 2.5 12C2.5 10.6167 2.7625 9.31667 3.2875 8.1C3.8125 6.88333 4.525 5.825 5.425 4.925C6.325 4.025 7.38333 3.3125 8.6 2.7875C9.81667 2.2625 11.1167 2 12.5 2C13.8833 2 15.1833 2.2625 16.4 2.7875C17.6167 3.3125 18.675 4.025 19.575 4.925C20.475 5.825 21.1875 6.88333 21.7125 8.1C22.2375 9.31667 22.5 10.6167 22.5 12C22.5 13.3833 22.2375 14.6833 21.7125 15.9C21.1875 17.1167 20.475 18.175 19.575 19.075C18.675 19.975 17.6167 20.6875 16.4 21.2125C15.1833 21.7375 13.8833 22 12.5 22Z" fill="#FB4C4C"/>
                            </g>
                            </svg>
                        </div>
                        <h3 class="failed">Payment Failed</h3>
                        <p class="message">{{ errorMessage }}</p>
                    </div>
                </v-card>
                <div>
                    <v-card width="300px" elevation="0" color="#F9FAFB">
                        <v-card-title class="font-weight-bold text-h5 mx-1 mt-10">Payment Method</v-card-title>
                    </v-card>
                    <!-- <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="address" label="Billing address 1*" :rules="billingAddressRules" type="input" outlined
                                        class="mx-2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="address2" label="Billing address 2" v-validate="'max:100'" type="input" outlined
                                        class="mx-2"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" sm="3">
                                    <v-text-field v-model="zip" label="ZIP Code*" :rules="zipcodeRules" type="input" outlined class="mx-2"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-select v-model="state" :rules="stateRules" label="State*" :items="states" outlined class="mx-2"></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="city" label="City*" :rules="cityRules" type="input" outlined class="mx-2"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container> -->
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <new-card-number  @card-number-updated="handleCardNumberUpdate" class="mx-2"></new-card-number>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="name_on_card" label="Name on Card*" type="input" :rules="nameRules" outlined class="mx-2"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>

                                <!-- IMPORTANT: This field is hidden from the UI. It is used for handling autofill data (expiration date (MM) and year (YYYY)). -->
                              
                                    <v-text-field v-model="exp_cc" name="cc-exp" autocomplete="cc-exp" class="hidden-field"></v-text-field>

                                <!------------------------------------------>

                                <v-col cols="6" sm="3">
                                    <v-select v-model="exp_mo" label="MM" :items="months" :rules="monthRules" outlined class="mx-2"></v-select>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-select v-model="exp_yr" label="YYYY" :items="yearList" :rules="yearRules" outlined class="mx-2"></v-select>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <new-card-cvv @card-cvv-updated="handleCardCVVUpdate" class="mx-2"/>
                                </v-col>
                                <v-col cols="6" sm="3">
                                    <v-text-field v-model="zip" label="ZIP Code*" :rules="zipcodeRules" type="input" outlined class="mx-2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-row class="my-3 ml-4 justify-content: space-between">
                                        <v-img src="../assets/images/Bitmap-3.png" max-width="56" max-height="36"></v-img>
                                        <v-img src="../assets/images/Bitmap-1.png" max-width="56" max-height="36"></v-img>
                                        <v-img src="../assets/images/Bitmap-2.png" max-width="56" max-height="36"></v-img>
                                        <v-img src="../assets/images/Bitmap.png" max-width="56" max-height="36"></v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-container>
                        <v-row>
                            <!-- <v-btn type="submit" class="mx-auto" height="55" min-width="299" color="#0096FF"> -->
                            <v-btn type="submit" @click="makePayment" class="mx-auto" height="55" min-width="299" color="#0096FF" :disabled="isPaymentButtonDisabled || loading">
                                <div v-if="!loading" style="color: #FFFFFF; line-height: 22px; font-style: sans-serif; font-size: 18px;">Make a payment</div>
                                <div v-else>
                                    <v-progress-circular
                                        indeterminate
                                        color="#0096FF"
                                        size="32"
                                        width="4"
                                    ></v-progress-circular>
                                </div>
                            </v-btn>
                        </v-row>
                    </v-container>
                </div>
            </v-card>
        </div>
    </div>
    
</template>
  
<script>
import jwt from 'jsonwebtoken';
import zlib from 'zlib';
import moment from 'moment';
import axios from 'axios';
import {  mapActions, mapGetters, mapMutations } from 'vuex';

const appId = process.env.VUE_APP_HUMMINGBIRD_APP_ID
export default ({
    name: 'oneTimePayment-layout',
    data: () => ({
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        jwtData: null,
        expirationTime: null,
        formattedTime: "Link expires in --:--:-- hours",
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        name_on_card: '',
        card_number: '',
        cvv2: '',
        exp_mo: '',
        exp_yr: '',
        logo: '',
        Invoices: [],
        loading: false,
        states: [
            'AA', 'AE', 'AP', 'AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
        ],
        totalAmount: 0,
        nameRules: [
            (v) => !!v || 'Name is required',
        ],
        // cityRules: [
        //     (v) => !!v || 'City is required',
        // ],
        // stateRules: [
        //     (v) => !!v || 'State is required',
        // ],
        // billingAddressRules: [
        //     (v) => !!v || 'Billing Address is required',
        // ],
        monthRules: [
            (v) => !!v || 'Month is required',
            (v) => /^[0-9]{2}$/.test(v) || 'Month must be 2 digits',
            (v) => parseInt(v) >= 1 && parseInt(v) <= 12 || 'Invalid month',
        ],
        yearRules: [
            (v) => !!v || 'Year is required',
            (v) => /^[0-9]{4}$/.test(v) || 'Year must be 4 digits',
            (v) => parseInt(v) >= new Date().getFullYear() || 'Card has expired',
        ],
        property_id: '',
        contact_id: '',
        jwtToken: '',
        errorMessage: null,
        valid: true,
        contact: {},
        paymentSuccess: false,
    }),
    async created() {
        const token = this.extractTokenFromURL();
        let decodedToken = jwt.decode(token);
        this.expirationTime = decodedToken.exp;
        this.jwtData = this.parseJwtPayload(decodedToken.payload)
        this.totalAmount = this.calculateTotalAmount(this.jwtData)
        this.logo = this.jwtData.logo;
        this.property_id = this.jwtData?.space_info[0]?.Unit?.property_id;
        this.contact_id = this.jwtData.contact_id;
        this.contact =  this.jwtData.contact;
        this.jwtToken = this.jwtData.otp_token
        if (this.expirationTime) {
            this.startTimer();
        }
        const updateURL = `${process.env.VUE_APP_GDS_BASE_URL}/v3/applications/${process.env.VUE_APP_HUMMINGBIRD_APP_ID}/v1/companies/${this.jwtData.company_id}/contacts/${this.contact_id}/get-payment-flag`
        const url = window.location.href;
        let params={
            otp_id: url.match(/\/([^/]+)\/pay/)[1],
        }
        let headers = {
            'X-storageapi-key': process.env.VUE_APP_GDS_API_KEY,
            'X-storageapi-date': String(Date.now()),
        } 
        const response = await axios.get(updateURL,{
			headers: headers,
			params: params,
		});
        const flag = response.data.applicationData[`${appId}`][0].data.id[0].flag;
		if (flag === 1) {
			this.$router.push({ name: 'invalid'});
		} else {
			this.$router.push({ name: 'onetimepayment' });
        }
    },
    mounted() {
        this.filterInvoices();
    },
    computed: {
        yearList() {
            let year = moment().format('YYYY');
            let years = [];
            for (let i = 0; i < 11; i++) {
                years.push({
                    text: (+year + i).toString(),
                    value: (+year + i).toString()
                })
            }
            return years;
        },
        isPaymentButtonDisabled() {
            return !this.zipCodeValidator(this.zip)
                || this.exp_mo.trim() === ''
                || this.exp_mo.trim() === ''
                || this.name_on_card.trim() === ''
                || this.card_number.trim() === ''
                || this.cvv2.trim() === '' ||
                this.cvv2.length !== 3 && this.cvv2.length !== 4;
        },
        zipcodeRules() {
            return [
                (v) => !!v || 'ZIP Code is required', 
                (v) => this.zipCodeValidator(v) || 'Invalid ZIP Code',
            ];
        },
        ...mapGetters({
            getPaymentStatus: 'paymentsStore/getPaymentStatus',
        }),
        exp_cc: {
            get() {
                return this.exp_mo && this.exp_yr ? `${this.exp_mo}/${this.exp_yr}` : ""
            },
            set(value) {
                if (value){
                    value = value.trim()
                    const [month, year] = value.split("/").map((part) => part?.trim())
                    this.exp_mo = month || ""
                    this.exp_yr = year?.length === 2 ? `20${year}` : year || ""
                } else {
                    this.exp_mo = ""
                    this.exp_yr = ""
                }
            }
        },
    },
    filters:{
        totalLease(space) {
            return space.OpenInvoices.reduce((total, invoice) => {
                return total + invoice.balance;
            }, 0);
        },
    },
    methods: {
        ...mapActions({
            updatePaymentStatus : 'paymentsStore/updatePaymentStatus',
            updateContactCompanyData : 'paymentsStore/updateContactCompanyData'
        }),
        ...mapMutations({
            setPaymentStatus :  'paymentsStore/setPaymentStatus',
            setContactCompanyData : 'paymentsStore/setContactCompanyData'
        }),
        zipCodeValidator(zipcode) {
            return new RegExp(/^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/g).test(zipcode);
        },
        handleCardNumberUpdate(cardNumber) {
            this.card_number = cardNumber;
        },
        handleCardCVVUpdate(cvv) {
            this.cvv2 = cvv;
        },
        filterInvoices() {
            this.Invoices = this.jwtData.space_info.map(space => {
                return space.OpenInvoices.map(invoice => ({
                id: invoice.id,
                number: invoice.number,
                balance: invoice.balance,
                date: invoice.date,
                period_start: invoice.period_start,
                period_end: invoice.period_end,
                lease_id: space.lease_id,
                due: invoice.due,
                credits_amount_used: 0, 
                amount: invoice.balance,
                }));
            }).flat();
        },
        async makePayment() {
            this.loading = true;
            const apiUrl = `${process.env.VUE_APP_GDS_BASE_URL}/v3/applications/${process.env.VUE_APP_HUMMINGBIRD_APP_ID}/v1/companies/${this.jwtData.company_id}/payments/bulk`
            let headers = {
                'X-storageapi-key': process.env.VUE_APP_GDS_API_KEY,
                'X-storageapi-date': String(Date.now()),
            } 
            const payload = {
                "payment": {
                    "property_id": this.property_id,
                    "contact_id": this.contact_id,
                    "payment_method_id": "",
                    "type": "card",
                    "credit_type": "",
                    "number": "",
                    "ref_name": "",
                    "source": "paymentlink",
                    "amount": this.totalAmount,
                    "date": "",
                    "transaction_id": "",
                    "amt_remaining": "",
                    "source_property": this.property_id,
                    "amount_tendered": null
                },
                "paymentMethod": {
                    "type": "card",
                    "name_on_card": this.name_on_card,
                    "card_number": this.card_number,
                    "exp_mo": this.exp_mo,
                    "exp_yr": this.exp_yr,
                    "cvv2": this.cvv2,
                    "save_to_account": false,
                    "auto_charge": false,
                    // "address": this.address,
                    // "address2": this.address2,
                    // "city": this.city,
                    // "state": this.state,
                    "zip": this.zip,
                    // "country": this.country,
                },
                "contact_id": this.contact_id,
                "property_id": this.property_id,
                "leases": [],
                "auto_pay_leases": null,
                "Invoices": this.Invoices,
                "use_credits": false,
                "contact": this.contact,
            }
            await axios
                .post(apiUrl, payload, { headers })
                .then(async (response) => {
                console.log('Payment success', response.data);
                const updateURL = `${process.env.VUE_APP_GDS_BASE_URL}/v3/applications/${process.env.VUE_APP_HUMMINGBIRD_APP_ID}/v1/companies/${this.jwtData.company_id}/contacts/${this.contact_id}/update-payment-flag`
                    const url = window.location.href;
                    this.paymentSuccess= true
                    if(this.paymentSuccess){
                        let payload={
                            token: url.match(/\/pay\/([^/]+)/)[1],
                        }
                        await axios
                            .put(updateURL, payload, { headers })
                            .then(async (response) => {
                            console.log('response', response.data);
                        })
                    }
                //this.$router.push({ name: 'thankyou', params: { paymentId, headers } });
                this.loading = false;
                this.$router.push({ name: 'thankyou'});
                window.history.replaceState(null, null,'/done');
                })
                .catch(async (error) => {
                    this.loading = false;
                    console.error('Payment error', error.response.data);
                    this.errorMessage = error.response.data.applicationData[`${appId}`][0].msg;
                    setTimeout(() => {
                        const errorMessageElement = document.getElementById('error-message');
                        if (errorMessageElement) {
                            errorMessageElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 500);
                    setTimeout(() => {
                        this.errorMessage = null;
                    }, 5000);
                });
        },
        calculateTotalAmount(jwtData) {
            const totalBalance = jwtData.space_info.reduce((acc, unit) => {
            unit.OpenInvoices.forEach((invoice) => {
                acc += invoice.balance;
            });
                return acc;
            }, 0);
            return totalBalance;
        },
        parseJwtPayload(payload) {
            const buffer = Buffer.from(payload, "base64");
            const payloadJson = zlib.inflateRawSync(buffer);
            return JSON.parse(payloadJson);
        },
        extractTokenFromURL() {
            const url = window.location.href;
            const tokenMatch = url.match(/\/pay\/([^/]+)/);
            if (tokenMatch && tokenMatch[1]) {
                return tokenMatch[1];
            }
            return null;
        },
        startTimer() {
            if (this.expirationTime) {
                this.timerInterval = setInterval(this.updateTimer, 1000);
            }
        },
        updateTimer() {
            const currentTime = Math.floor(Date.now() / 1000); 
            const remainingTime = this.expirationTime - currentTime;
            if (remainingTime <= 0) {
                clearInterval(this.timerInterval);
                this.formattedTime = "Link has expired"; 
                this.$router.push('/invalid')
            } else {
                const hours = Math.floor(remainingTime / 3600);
                const minutes = Math.floor((remainingTime % 3600) / 60);
                const seconds = remainingTime % 60;
                this.formattedTime = `Link expires in ${hours}h ${minutes}m ${seconds}s`;
            }
        },
    },
    beforeDestroy() {
        clearInterval(this.timerInterval);
    },
});
</script>

<style scoped>
.contents {
    display: flex;
    width: 703px;
    height: 85px;
    padding: 0px 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}

.header-wrap {
    display: flex;
    height: 110px;
    padding: 10px 0px;
    justify-content: space-around;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--primary-cloud-main, #DFE3E8);
    background: #FFF;
}

.expire-content {
    color: #000;
    font-family: sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.invoice-row .caption {
    color: #637381;
}

.invoice-line-row {
    margin-bottom: 0;
    padding: 0px 20px;
}

.expansion-header {
    padding-inline-end: 36px;
    height: 82px;
}

.invoice-line-row .col {
    padding: 0;
}

.invoice-total-row>.col {
    padding: 10px;
}

.invoice-line-row.total {
    border-top: 1px solid #dfe3e8;
    margin-top: 20px;
}

.invoice-line-row.total>.col {
    padding-top: 15px;
}

.space-no {
    color: #000;
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.amount {
    color: var(--primary-grayscale-white, #FFF);
    font-family: sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 78px;
}

.charge-total {
    color: var(--primary-grayscale-white, #FFF);
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 202px;
}

.address-text {
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
}

.charge-block {
    display: flex;
    justify-content: space-between;
}

.error-message {
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--primary-status-destructive, #FB4C4C);
    background-color: #F8E5E1;
}

.failed {
    display: flex;
    width: 35%;
    top: 20%;
    justify-content: center;
    color: var(--text-night, #101318);
    font-family: sans-serif;
    font-size: 15px;
    font-style: normal;
}
.svg {
    display: flex;
    width: 100px;
    position: relative;
    top: 22%;
    justify-content: space-around;
}

.message {
    display: flex;
    padding-left: 6%;
    padding-top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    font-family: sans-serif;
    font-size: 15px;
    font-style: normal;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
}
.v-main__wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    background-color: rgb(249, 250, 251);
}

.hidden-field {
    position: absolute;
    opacity: 0;
    height: 0;
    pointer-events: none;
}

@media screen and (max-width: 767px) {
    .error-message {
        height: auto; 
    }

    .failed {
        width: 50%;
        top: 10%; 
    }

    .svg {
        width: 50px;
        height: 3px;
        top: 12%; 
        display: flex;
    }

    .message {
        padding-left: 4%; /* Example adjustment */
        
    }
}
</style>
