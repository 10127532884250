<template>
    <hb-tooltip>
        <template v-slot:item>
            <hb-link tooltip="Help" @click="getHelpLink" :style="{ position, top }" target="_blank" :class="{ 'pr-2': !nav }">
                <hb-icon>mdi-help-circle-outline</hb-icon>
            </hb-link>
        </template>
        <template v-slot:body>
            Help
        </template>
    </hb-tooltip>
</template>


<script>
 
import { mapActions } from 'vuex';

export default {
    name: "HelpJuice",
    props: {
        nav: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: ''
        },
        top: {
            type: String,
            default: ''
        }
    },
    methods: {
        ...mapActions({
            getHelpJuiceLink: 'utilsStore/getHelpJuiceLink'
        }),
        async getHelpLink() {
            try {
                let response = await this.getHelpJuiceLink();
                let newTab = window.open();
                newTab.location.href = response.help_url;
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>

