<template>
  <v-hover v-slot="{ hover }">
    <v-timeline-item
      v-bind="attrs"
      v-on="$listeners"
      :style="eventStyle"
      class="pb-3 pt-2 hb-timeline-item-wrapper"
      :class="{ 'hb-timeline-item-hover' : (hover && editable && (!turnOffEdit || !turnOffDelete)) || menu }"
    >
      <template
        v-for="(_, scopedSlot) in $scopedSlots"
        v-slot:[scopedSlot]="slotData"
      >
        <slot :name="scopedSlot" v-bind="slotData" />
      </template>
      <template v-for="(_, slot) in $slots" v-slot:[slot]>
        <slot :name="slot" />
      </template>
      <slot>
        <v-row>
          <v-col
            v-if="showFirstColumn"
            cols="2"
            class="hb-timeline-item-day-section py-0 px-2"
          >
            <slot name="firstColumn">
              <v-row no-gutters>
                <div v-if="isLinkedStage" class="pr-1 hb-timeline-item-linked-stage-icon">
                  <hb-tooltip>
                    <template v-slot:item>
                      <HbIcon small color="#F26500" mdi-code="mdi-custom-linked" />
                    </template>
                    <template v-slot:body>
                      Linked Stage
                    </template>
                  </hb-tooltip>
                </div>
                <div
                  class="hb-timeline-item-font-body-medium"
                  :class="{ 'hb-timeline-item-today-bottom-margin-adjustment' : daySectionTitle }"
                  v-if="isToday"
                  :style="getFontColor('daySectionTitleFontColor')"
                >
                  Today
                </div>
                <div
                  class="hb-timeline-item-font-body-medium"
                  v-if="daySectionTitle"
                  :style="getFontColor('daySectionTitleFontColor')"
                >
                  {{ daySectionTitle }}
                </div>
              </v-row>
              <div
                class="hb-timeline-item-font-caption hb-timeline-item-text-light hb-timeline-item-bottom-margin"
                :style="getFontColor('descriptionFontColor')"
                v-if="daySectionDescriptionOne"
              >
                {{ daySectionDescriptionOne }}
              </div>
              <div
                class="hb-timeline-item-font-caption hb-timeline-item-text-light hb-timeline-item-bottom-margin"
                :style="getFontColor('descriptionFontColor')"
                v-if="daySectionDescriptionTwo"
              >
                {{ daySectionDescriptionTwo }}
              </div>
            </slot>
          </v-col>
          <v-col v-if="showSecondColumn" class="py-0 pr-4 mr-3">
            <slot name="secondColumn">
              <v-row no-gutters>
                <v-col :cols="editable ? 10 : 12" class="ma-0 pa-0">
                  <div
                    v-if="nameSectionTitle"
                    class="hb-timeline-item-font-body-medium"
                    :style="getFontColor('nameSectionTitleFontColor')"
                  >
                    {{ nameSectionTitle }}
                    <span class="pl-1" v-if="nameSectionTitleIcon">
                      <hb-tooltip :disabled="!nameSectionTitleIconTooltipHeader && !nameSectionTitleIconTooltipBody">
                        <template v-slot:item>
                          <HbIcon small :color="nameSectionTitleIconColor" :mdi-code="nameSectionTitleIcon" />
                        </template>
                        <template v-slot:header v-if="nameSectionTitleIconTooltipHeader">
                          {{ nameSectionTitleIconTooltipHeader }}
                        </template>
                        <template v-slot:body v-if="nameSectionTitleIconTooltipBody">
                          {{ nameSectionTitleIconTooltipBody }}
                        </template>
                      </hb-tooltip>
                    </span>
                  </div>
                  <div
                    v-if="nameSectionSubTitle"
                    class="hb-timeline-item-font-caption-medium hb-timeline-item-bottom-margin"
                    :style="getFontColor('nameSectionSubTitleFontColor')"
                  >
                    {{ nameSectionSubTitle }}<span v-if="nameSectionSubTitleDescription" class="font-weight-regular">: {{ nameSectionSubTitleDescription }}</span>
                  </div>
                  <div
                    class="hb-timeline-item-font-caption hb-timeline-item-text-light hb-timeline-item-bottom-margin"
                    :style="getFontColor('descriptionFontColor')"
                    v-if="nameSectionDescriptionOne"
                  >
                    {{ nameSectionDescriptionOne }}
                  </div>
                  <div
                    class="hb-timeline-item-font-caption hb-timeline-item-text-light hb-timeline-item-bottom-margin"
                    :style="getFontColor('descriptionFontColor')"
                    v-if="nameSectionDescriptionTwo"
                  >
                    {{ nameSectionDescriptionTwo }}
                  </div>
                  <div class="hb-timeline-item-font-caption">
                    <hb-link
                      :color="actionToggleColor"
                      v-if="showActionToggle && hasActions"
                      @click="toggle = !toggle"
                    >
                      {{ (toggle ? "Hide " : "Show ") + actionLabel }}
                      {{ actionCountData }}
                    </hb-link>
                  </div>
                </v-col>
                <v-spacer v-if="(hover && editable && (!turnOffEdit || !turnOffDelete)) || menu"></v-spacer>
                <v-col cols="1" v-if="(hover && editable && (!turnOffEdit || !turnOffDelete)) || menu">
                  <hb-menu options style="float:right;" class="mt-1" v-model="menu">
                    <v-list>

                        <v-list-item
                          v-if="!turnOffEdit"
                          :ripple="false"
                          @click="$emit('edit-clicked', $event)"
                          :disabled="disableEdit"
                        >
                            <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-if="!turnOffDelete"
                          :ripple="false"
                          @click="$emit('delete-clicked', $event)"
                          :disabled="disableDelete"
                        >
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>

                    </v-list>                   
                  </hb-menu>
                </v-col>
              </v-row>
              <v-expand-transition>
                <!-- Show this section only in following conditions:
                      - hasActions -> true && showActionToggle -> true && toggle -> true
                      - hasActions -> true && showActionToggle -> false 
                -->
                <div v-if="hasActions && (!showActionToggle || toggle)">
                  <div
                    :class="[
                      'mt-2',
                      actionBorder ? 'hb-timeline-item-action-wrap' : '',
                      ...actionClassList,
                    ]"
                  >
                    <slot name="action"></slot>
                  </div>
                </div>
              </v-expand-transition>
              <div v-if="hasAdditionalActionsSlot" class="mt-2">
                <slot name="additionalActions"></slot>
              </div>
              <div v-if="audit" class="mt-2">
                <div v-if="auditVerified" class="hb-timeline-verified-wrapper" :class="{ 'pt-2' : !auditVerifiedAllowUndo }">
                  <HbIcon
                    small
                    color="#637381"
                    mdi-code="mdi-check"
                    class="pr-1"
                    style="position:relative;top:-1px;"
                  />Verified<span
                      v-if="auditVerifiedBy"
                      class="pl-1"
                    >by {{ auditVerifiedBy }}</span>
                    <span
                      v-if="auditVerifiedOn"
                      class="pl-1"
                    >({{ auditVerifiedOn }})</span>
                  <hb-link
                    v-if="auditVerifiedAllowUndo"
                    class="hb-timeline-audit-undo-link"
                    @click="$emit('audit-undo-clicked', $event)"
                  >Undo</hb-link>
                </div>
                <hb-btn
                  v-else
                  color="secondary"
                  small
                  @click="$emit('audit-mark-as-verified-clicked', $event)"
                >
                  Mark as Verified
                </hb-btn>
              </div>
            </slot>
          </v-col>
        </v-row>
      </slot>
    </v-timeline-item>
  </v-hover>
</template>

<script>
import HbBtn from "./HbBtn.vue";
import HbIcon from "./HbIcon.vue";
import HbLink from "./HbLink.vue";

export default {
  name: "HbTimelineItem",
  components: {
    HbBtn,
    HbIcon,
    HbLink,
  },
  data() {
    return {
      /**
       *
       *  These styles will be applied automatically based on event type
       *
       *    - if past : {
       *                  icon: 'mdi-check-circle-outline' [check inside circle icon]
       *                  iconColor: #00848E [teal]
       *                  lineColor: #00848E [teal]
       *                  backgroundColor: #F9FAFB [light gray]
       *                  daySectionTitleFontColor: #101318 [~black]
       *                  nameSectionTitleFontColor: #101318 [~black]
       *                  descriptionFontColor: #637381 [~gray]
       *                }
       *    - if today : {
       *                  icon: 'mdi-check-circle-outline' [check inside circle icon]
       *                  iconColor: #00848E [teal]
       *                  lineColor: #00848E [teal]
       *                  backgroundColor: #FFFFFF [white]
       *                  daySectionTitleFontColor: #101318 [~black]
       *                  nameSectionTitleFontColor: #101318 [~black]
       *                  descriptionFontColor: #637381 [~gray]
       *                }
       *    - if current : {
       *                  icon: 'mdi-record-circle' [bold circle icon]
       *                  iconColor: #00848E [teal]
       *                  lineColor: #DFE3E8 [~gray]
       *                  backgroundColor: #E0F5F5 [light teal]
       *                  daySectionTitleFontColor: #101318 [~black]
       *                  nameSectionTitleFontColor: #101318 [~black]
       *                  descriptionFontColor: #637381 [~gray]
       *                }
       *    - if error : {
       *                  icon: 'mdi-minus-circle-outline' [minus inside circle icon]
       *                  iconColor: #FB4C4C [~red]
       *                  lineColor: #00848E [teal]
       *                  backgroundColor: #FFFFFF [white]
       *                  daySectionTitleFontColor: #101318 [~red]
       *                  nameSectionTitleFontColor: #101318 [~black]
       *                  descriptionFontColor: #637381 [~gray]
       *                }
       *    - if future : {
       *                  icon: 'mdi-circle-outline' [minus inside circle icon]
       *                  iconColor: #DFE3E8 [~gray]
       *                  lineColor: ##DFE3E8 [~gray]
       *                  backgroundColor: #FFFFFF [white]
       *                  daySectionTitleFontColor: #A5B4BF [disabled gray]
       *                  nameSectionTitleFontColor: #A5B4BF [disabled gray]
       *                  descriptionFontColor: #A5B4BF [disabled gray]
       *                }
       */
      defaultStyle: {
        default: {
          icon: "mdi-circle",
          iconColor: "#DFE3E8",
          "--lineColor": "#DFE3E8",
          backgroundColor: "#FFFFFF",
          daySectionTitleFontColor: "#101318",
          nameSectionTitleFontColor: "#101318",
          nameSectionSubTitleFontColor: "#101318",
          descriptionFontColor: "#637381",
        },
        past: {
          icon: "mdi-check-circle",
          iconColor: "#02AD0F",
          "--lineColor": "#00848E",
          backgroundColor: "#F9FAFB",
          daySectionTitleFontColor: "#101318",
          nameSectionTitleFontColor: "#101318",
          nameSectionSubTitleFontColor: "#101318",
          descriptionFontColor: "#637381",
        },
        today: {
          icon: "mdi-check-circle",
          iconColor: "#02AD0F",
          "--lineColor": "#00848E",
          backgroundColor: "#FFFFFF",
          daySectionTitleFontColor: "#101318",
          nameSectionTitleFontColor: "#101318",
          nameSectionSubTitleFontColor: "#101318",
          descriptionFontColor: "#637381",
        },
        current: {
          icon: "mdi-record-circle",
          iconColor: "#00848E",
          "--lineColor": "#DFE3E8",
          backgroundColor: "#E0F5F5",
          daySectionTitleFontColor: "#101318",
          nameSectionTitleFontColor: "#101318",
          nameSectionSubTitleFontColor: "#101318",
          descriptionFontColor: "#637381",
        },
        error: {
          icon: "mdi-minus-circle",
          iconColor: "#FB4C4C",
          "--lineColor": "#00848E",
          backgroundColor: "#F9FAFB",
          daySectionTitleFontColor: "#101318",
          nameSectionTitleFontColor: "#101318",
          nameSectionSubTitleFontColor: "#101318",
          descriptionFontColor: "#637381",
        },
        future: {
          icon: "mdi-circle",
          iconColor: "#DFE3E8",
          "--lineColor": "#DFE3E8",
          backgroundColor: "#FFFFFF",
          daySectionTitleFontColor: "#A5B4BF",
          nameSectionTitleFontColor: "#A5B4BF",
          nameSectionSubTitleFontColor: "#A5B4BF",
          descriptionFontColor: "#A5B4BF",
        },
      },
      toggle: this.expandAction,
      menu: false,
    };
  },

  props: {
    stageType: {
      type: String,
      required: false,
      default: "default",
    },
    filledIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    customIcon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      required: false,
    },
    today: {
      type: Boolean,
      required: false,
      default: false,
    },
    daySectionTitle: {
      type: String,
      required: false,
    },
    daySectionDescriptionOne: {
      type: String,
      required: false,
    },
    daySectionDescriptionTwo: {
      type: String,
      required: false,
    },
    nameSectionTitle: {
      type: String,
      required: false,
    },
    nameSectionTitleIcon: {
      type: String,
      required: false,
    },
    nameSectionTitleIconColor: {
      type: String,
      default: '#101318',
      required: false,
    },
    nameSectionTitleIconTooltipHeader: {
      type: String,
      required: false,
    },
    nameSectionTitleIconTooltipBody: {
      type: String,
      required: false,
    },
    nameSectionSubTitle: {
      type: String,
      required: false,
    },
    nameSectionSubTitleDescription: {
      type: String,
      required: false,
    },
    nameSectionDescriptionOne: {
      type: String,
      required: false,
    },
    nameSectionDescriptionTwo: {
      type: String,
      required: false,
    },
    actionLabel: {
      type: String,
      required: false,
      default: "Actions",
    },
    expandAction: {
      type: Boolean,
      required: true,
      default: true,
    },
    showActionToggle: {
      type: Boolean,
      required: false,
    },
    actionToggleColor: {
      type: String,
      required: false,
      default: "primary",
    },
    actionBorder: {
      type: Boolean,
      required: true,
      default: true,
    },
    actionWrapperClass: {
      type: [Array, String],
      required: false,
      default: "pa-3",
    },
    actionCount: {
      type: Number,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
    },
    daySectionTitleFontColor: {
      type: String,
      required: false,
    },
    nameSectionTitleFontColor: {
      type: String,
      required: false ,
    },
    nameSectionSubTitleFontColor: {
      type: String,
      required: false,
    },
    descriptionFontColor: {
      type: String,
      required: false,
    },
    lineColor: {
      type: String,
      required: false,
    },
    audit: {
      type: Boolean,
      default: false,
    },
    auditVerified: {
      type: Boolean,
      default: false,
    },
    auditVerifiedBy: {
      type: String,
      default: undefined,
    },
    auditVerifiedOn: {
      type: String,
      default: undefined,
    },
    auditVerifiedAllowUndo: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    turnOffEdit: {
      type: Boolean,
      default: false,
    },
    turnOffDelete: {
      type: Boolean,
      default: false,
    },
    isLinkedStage: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    actionClassList() {
      return typeof this.actionWrapperClass == "string"
        ? [this.actionWrapperClass]
        : this.actionWrapperClass;
    },
    actionCountData() {
      let count = Number(this.actionCount)
      return !isNaN(count) && count ? `(${count})` : ""
    },
    attrs() {
      return {
        small: true,
        color: this.backgroundColor ?? this.eventStyle.backgroundColor,
        icon: this.customIcon
          ? this.customIcon
          : this.eventStyle.icon + (this.computedFilledIcon ? "" : "-outline"),
        "icon-color": this.iconColor ?? this.eventStyle.iconColor,
        ...this.$attrs,
      };
    },
    eventStyle() {
      let eventStyle =
        this.defaultStyle[this.stageType] ?? this.defaultStyle["default"];
      return {
        ...eventStyle,
        ...(this.backgroundColor
          ? {
              backgroundColor: this.backgroundColor,
            }
          : {}),
        ...(this.daySectionTitleFontColor
          ? {
              daySectionTitleFontColor: this.daySectionTitleFontColor,
            }
          : {}),
        ...(this.nameSectionTitleFontColor
          ? {
              nameSectionTitleFontColor: this.nameSectionTitleFontColor,
            }
          : {}),
        ...(this.nameSectionSubTitleFontColor
          ? {
              nameSectionSubTitleFontColor: this.nameSectionSubTitleFontColor,
            }
          : {}),
        ...(this.descriptionFontColor
          ? {
              descriptionFontColor: this.descriptionFontColor,
            }
          : {}),
        ...(this.lineColor
          ? {
              '--lineColor': this.lineColor,
            }
          : {}),
      };
    },
    isToday(){
      return this.today || this.stageType == 'today'
    },
    showFirstColumn() {
      return !!(
        this.hasFirstColumnSlot ||
        this.isToday ||
        this.daySectionTitle ||
        this.daySectionDescriptionOne ||
        this.daySectionDescriptionTwo
      );
    },
    showSecondColumn() {
      return !!(
        this.hasSecondColumnSlot ||
        this.nameSectionTitle ||
        this.nameSectionSubTitle ||
        this.nameSectionDescriptionOne ||
        this.nameSectionDescriptionTwo ||
        this.hasActions
      );
    },
    hasActions() {
      return this.$slots.action?.[0];
    },
    hasSecondColumnSlot() {
      return this.$slots.secondColumn?.[0];
    },
    hasFirstColumnSlot() {
      return this.$slots.firstColumn?.[0];
    },
    hasAdditionalActionsSlot() {
      return this.$slots.additionalActions?.[0];
    },
    computedFilledIcon() {
      if(this.filledIcon){
        return this.filledIcon;
      }
      if(this.stageType === 'today' || this.stageType === 'past' || this.stageType === 'error' || this.stageType === 'current'){
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    getFontColor(key) {
      if(this.isLinkedStage && (key === 'nameSectionTitleFontColor' || key === 'daySectionTitleFontColor')){
        return "color:#F26500";
      } else {
        return this.eventStyle?.[key] ? "color:" + this.eventStyle[key] : "";
      }
    },
  },
};
</script>
<style scoped>
.hb-timeline-item-font-body-medium {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.hb-timeline-item-font-caption-medium {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}
.hb-timeline-item-line-height-19 {
  line-height: 19px;
}

.hb-timeline-item-font-caption {
  font-size: 12px;
  line-height: 16px;
}
.hb-timeline-item-text-light {
  color: #637381;
}
.hb-timeline-item-day-section {
  min-width: 92px;
  max-width: 92px;
}
.hb-timeline-item-action-wrap {
  border: solid 1px #c4cdd5;
  border-radius: 8px;
}
.hb-timeline-verified-wrapper {
  border-radius: 4px;
  font-size: 12px;
  background-color: #E0F5F5;
  min-height: 36px;
  padding: 6px 12px 10px 12px;
  color: #637381;
}
.hb-timeline-audit-undo-link {
  font-size: 14px;
  position: relative;
  top: .5px;
  margin-left: 10px;
}

.hb-timeline-item-bottom-margin {
  margin-bottom: 3px;
}

.hb-timeline-item-today-bottom-margin-adjustment {
  margin-bottom: -3px;
}

.hb-timeline-item-hover {
  background: #F9FAFB !important;
}

.hb-timeline-item-linked-stage-icon {
  position: relative;
  top: 4px;
}

</style>

<style>
.hb-timeline-item-wrapper .v-timeline-item__dot {
  background-color: var(--backgroundColor) !important;
}

.hb-timeline-item-wrapper .v-timeline-item__body {
  max-width: unset;
}

.hb-timeline-item-wrapper .v-timeline-item__divider {
    min-width: unset;
    margin: 0 24px 0 16px;
}

.hb-timeline-item-wrapper .v-timeline-item__divider::before {
    content: "";
    height: calc(100% + 20px);
    position: absolute;
    top: -8px;
    width: 1px;
    background-color: var(--lineColor);
}

.hb-timeline-wrapper.hb-timeline-top-icon .hb-timeline-item-wrapper .v-timeline-item__divider::before {
  content: "";
  height: calc(100% - 12px);
  top: 24px;
}

.hb-timeline-item-wrapper div, .hb-timeline-item-wrapper span, .hb-timeline-item-wrapper a {
  word-break: break-word;
}

.hb-timeline-item-hover .v-timeline-item__inner-dot {
  background: #F9FAFB !important;
}
</style>