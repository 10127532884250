<template>
  <div>
    <hb-modal
      v-model="dialog"
      title="Take a Payment"
      size="large"
      @close="$emit('close')"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-6 align-stretch hb-cloud-lighter">
          <payment-process
            :contactID="contact.id" 
            :propertyID="lease.Unit.property_id"
            paymentSource="BILL_DAY_CHANGE"
            >
          </payment-process>
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn class="mr-2" color="primary" @click="checkPaymentsErrors" :disabled="disable_payment" :loading="disable_payment">Process Payment</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import PaymentProcess from '../../includes/PaymentProcess/Index.vue';
import { EventBus } from '../../../EventBus.js';
import api from '../../../assets/api.js';
import moment from 'moment';

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "BillingOnTakePaymentModal",
  mixins: [ notificationMixin ],
  data() {
    return {
      disable_payment: false,
    };
  },
  components:{
    PaymentProcess
  },
  props: ["value", "contact", "lease"],
  created() {
    EventBus.$on('paymentReader', this.enablePayment);
  },
  destroyed() {
    EventBus.$off('paymentReader', this.enablePayment);
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject'
    }),
    ...mapMutations({
      setPaymentSource: "paymentsStore/setPaymentSource",
      createLeaseStructure: "paymentsStore/createLeaseStructure",
      setLeaseBilledMonths: "paymentsStore/setLeaseBilledMonths",
      setCheckErrors: "paymentsStore/setCheckErrors",
      checkOnlyCredit: 'paymentsStore/checkOnlyCredit',
      setLeaseProperty: 'paymentsStore/setLeaseProperty',
      pushInvoices: 'paymentsStore/pushInvoices'
    }),
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async processPayment() {
      this.disable_payment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disable_payment = false;
        return;
      }

      if(paymentInfo && paymentInfo.payment) {
        let response = {};
        try {
          const responseData = await this.payInvoice({paymentInfo: paymentInfo});
            response = {
              data: {
                msg: {
                  text: `The billing day has been updated to ${this.$options.filters.dayEnding(this.lease.bill_day)} of the month and the payment was processed on ${moment().format('MMM Do, YYYY @ h:mma')}.`
                }
              }
            };
        } catch(err){
          response = {
            error: err
          };
        } finally {
          this.enablePayment(response);
        }                
      }              
    },
    async enablePayment(response) {
      this.disable_payment = false;
      this.$emit('close');
      if(response && response.error) {
        this.showMessageNotification({ description: response.error });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('activeEdit', '');
        EventBus.$emit('reload_tenant_profile_leases');
      }
    },
    async payInvoice({ paymentInfo, dryRun = false }) {
      const billingOnData = {
        new_bill_day: this.lease.bill_day,
        payment_details: paymentInfo,
        dryrun: dryRun
      };
      const response = await api.post(this, `${api.LEASES}/${this.lease.id}/change-bill-day`, billingOnData);
      return response;
    },
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  }

}
</script>

<style lang="scss" scoped>
</style>