<template>

  <v-container dense>
    <v-row>
      <v-col v-if="!isGuestUser">
        <v-card
          outlined
        >
          <v-card-title
            class="px-6 py-3"
            primary-title
          >
            <span class="subtitle-1 font-weight-medium" v-if="payment.isAuction">Bill</span>
            <span class="subtitle-1 font-weight-medium" v-else>Bill to</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-4 px-6 d-flex flex-column" style="color: #101318;" v-if="payment.Contact">
            <span class="subtitle-2 font-weight-medium text-capitalize" v-if="payment.isAuction">Auction Winner</span>
            <span class="subtitle-2 font-weight-regular text-capitalize">{{payment.Contact.first}} {{payment.Contact.last}} </span>
            <span class="subtitle-2 font-weight-regular">{{payment.Contact.email}}</span>
            <span class="subtitle-2 font-weight-regular" v-if="payment.Contact.Phones && payment.Contact.Phones.length">{{payment.Contact.Phones[0].phone | formatPhone}}</span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.Contact.Addresses && payment.Contact.Addresses.length">{{payment.Contact.Addresses[0].Address.address}} {{payment.Contact.Addresses[0].Address.address2}}</span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.Contact.Addresses && payment.Contact.Addresses.length">{{ payment.Contact.Addresses[0].Address | formatAddress }}</span>
            <span class="subtitle-2 font-weight-regular text-capitalize" v-if="payment.isAuction && payment.LeaseAuction.license_number">Reseller License #: {{payment.LeaseAuction.license_number}}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          outlined
        >
        <v-card-title
          class="px-6 py-3"
          primary-title
        >
          <span class="subtitle-1 font-weight-medium">Payment Info</span>
        </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-4 px-6 d-flex flex-column hb-text-light">
            <span class="subtitle-1 font-weight-regular text-capitalize hb-text-accept"> Amount Paid</span>
            <div style="line-height: 18px;"> {{payment.created | addTimeOffset(payment.Property.utc_offset, 'MMM DD, YYYY @ h:mma')}}</div>
            <div class="font-weight-regular hb-text-night" style="line-height: 20px; font-size:24px; margin-top: 4px ; margin-bottom: 4px;"> {{ payment.amount | formatMoney}}</div>
            <div v-if="['card', 'ach'].indexOf(payment.method) >= 0">{{payment.PaymentMethod.card_type | capitalize}}**** {{ payment.PaymentMethod.card_end}}</div>
            <div v-else-if="['directdebit'].indexOf(payment.method) >= 0">Direct Debit**** {{ payment.PaymentMethod.card_end}}</div>
            <div v-else-if="['eftpos'].indexOf(payment.method) >= 0">EFTPOS {{payment.PaymentMethod.card_type | capitalize}}</div>
            <div v-else>{{payment.method | capitalize}} {{payment.PaymentMethod.card_type}}</div>
            <div v-if="['cash', 'check'].indexOf(payment.method) < 0 && payment.transaction_id">Transaction ID: {{payment.transaction_id}}</div>
            <div v-if="isInterProperty">Property: {{payment.Property.number}} - {{payment.Property.Address.city}} </div>
            <div v-if="payment.AcceptedBy"> Accepted By: {{ formatAcceptedBy(payment.AcceptedBy) }} </div>
            <!-- <div v-if="isInterProperty">Amount paid to property: 
              <span class="hb-font-body-medium hb-text-night">{{ getAppliedPaymentsToProperty() | formatMoney }}</span>
            </div>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.method === 'credit'">
              Credit<br />
              Date of Credit Issue: {{ payment.date | formatDate }}
            </span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.PaymentMethod && payment.PaymentMethod.type === 'Cash'">
              Cash Payment<br />
              Reference Name: {{ payment.ref_name }}
            </span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.PaymentMethod && payment.PaymentMethod.type === 'Check'">
              Check Payment<br />
              Reference Name: {{ payment.ref_name }}<br />
              Check Number: {{ payment.number  }}
            </span>
            <span class="subtitle-2 font-weight-regular" v-if="payment.PaymentMethod && payment.PaymentMethod.type === 'card'">
              Credit Card Payment<br />
              <span v-if="payment.PaymentMethod.card_type">{{ payment.PaymentMethod.card_type | capitalize }}<br /></span>
              xxxx-xxxx-xxxx-{{payment.PaymentMethod.card_end}}<br />
            </span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.PaymentMethod && payment.PaymentMethod.type === 'ach'">
              ACH<br />
              Reference Name: {{ payment.ref_name }}<br />
              Checking Account: XXXX{{ payment.PaymentMethod.card_end  }}
            </span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.PaymentMethod && payment.PaymentMethod.type === 'ACH'">
              ACH<br />
              Reference Name: {{ payment.ref_name }}<br />
              Checking Account: XXXX{{ payment.PaymentMethod.card_end  }}
            </span>
            <span class="subtitle-2 text-capitalize font-weight-regular" v-if="payment.transaction_id && isAdmin">
              <span class="subtitle-2 text-capitalize font-weight-regular"><br />Transaction ID: {{payment.transaction_id}}</span>
            </span> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          outlined
        >
          <v-card-title
            class="px-6 py-3"
            primary-title
          >
            <span class="subtitle-1 font-weight-medium">Charges</span>
          </v-card-title>
          <v-divider></v-divider>
          <span v-if="invoices.length !==0">
            <span v-for="(invoice, i) in invoices" :key="'invoice' + i">
              <v-card-text class="px-1 py-1" style="color:#101318;">
                <v-row>
                  <!-- <v-col cols="4" class="pl-8" v-if="invoice.Lease && invoice.Lease.id">
                    <strong>Space # {{invoice.Lease.Unit.number}}</strong><br />
                    <span v-if="invoice.Lease.Unit.Category">{{invoice.Lease.Unit.Category.name}}</span>
                  </v-col> -->
                  <v-col class="pr-2 pl-8">
                    <span class="text-caption" style="color:#637381;" v-if="!payment.isAuction">Invoice #{{invoice.number}}<span v-if="invoice.due"> (Due: {{ invoice.due | formatDateCustom('MMM DD, YYYY')}})</span></span><br />
                    <div class="text-caption text-capitalize font-weight-medium" v-if="invoice.Lease && invoice.Lease.Unit">
                      Space # {{invoice.Lease.Unit.number}}
                      <span v-if="invoice.Lease.Property">({{invoice.Lease.Property.number}} - {{ invoice.Lease.Property.Address.city }})</span>
                      <br />
                    </div>
                    <span class="text-caption text-capitalize font-weight-regular" v-if="payment.isAuction">{{payment.LeaseAuction.auction_type}}, {{payment.LeaseAuction.scheduled_date | formatDateCustom('MMM DD, YYYY @ h:mma')}}<br /></span>
                    <span class="text-caption text-capitalize font-weight-regular" v-for="(lines, i) in invoice.InvoiceLines" :key="'invoice-lines' + i">

                      <span v-if="lines.service_id">{{lines.Service.name}}</span>
                      <span v-else-if="lines.Product.default_type == 'cleaning'">{{lines.Product.name}}:<span class="font-weight-medium text-none"> To receive this deposit, the space must be clean by the end of the defind cleaning period ({{payment.LeaseAuction.cleaning_period}} days). </span></span>
                      <span v-else>{{lines.Product.name}}</span>

                      <span v-if="lines.Product.default_type == 'rent'"> ({{lines.start_date | formatDateCustom('MMM DD, YYYY')}} - {{lines.end_date | formatDateCustom('MMM DD, YYYY')}}) </span>
                      <span v-if="lines.Product.default_type == 'product' && lines.qty > 1"> (Qty: {{lines.qty}})</span>
                      <span style="float:right" class="pr-6">{{lines.cost | formatMoney}}</span><br />
                    </span>
                    <span v-if="invoice.total_tax !== 0">Taxes<span style="float:right" class="pr-6">{{invoice.total_tax | formatMoney}}</span><br /></span>
                    <span v-if="invoice.discounts !== 0">Discount Fee<span style="float:right" class="pr-6">-{{invoice.discounts | formatMoney}}</span><br /></span>

                    <div class="my-4 mr-6" style="border-bottom:1px solid #e2e2e2;"></div>
                    <span class="text-caption text-capitalize font-weight-medium">Invoice Total</span> <span style="float:right" class="pr-6"><span class="font-weight-medium">{{invoice.total_due | formatMoney}}</span></span><br />
                    <span class="text-caption text-capitalize font-weight-medium">Payment Applied</span> <span style="float:right" class="pr-6"><span class="font-weight-medium">{{ totalPayments(invoice) | formatMoney}}</span></span><br />
                    <span v-if="!isGuestUser" class="text-caption text-capitalize font-weight-medium">Invoice Balance Remaining</span> <span style="float:right" class="pr-6"><span class="font-weight-medium">{{invoice.balance_remaining | formatMoney}}</span></span><br />
                    <!-- <span class="text-caption text-capitalize font-weight-regular"> Balance Remaining <span style="float:right" class="pr-6">{{ remainingAmount(invoice) | formatMoney}}</span><br /></span> -->
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="ma-0 pa-0"></v-divider>
            </span>
          </span>
          <span v-if="invoices.length === 0">
            <v-card-text class="px-1 py-1" style="color:#101318;">
              <v-row>
                <v-col class="pr-2 pl-8">
                  <span class="text-caption font-weight-regular">Charges
                    <span style="float:right" class="pr-6">N/A</span><br />
                  </span>

                  <div class="my-4 mr-6" style="border-bottom:1px solid #e2e2e2;"></div>
                  <span class="text-caption text-capitalize font-weight-medium">Payment</span> <span style="float:right" class="pr-6"><span class="font-weight-medium">-{{payment.amount | formatMoney}}</span></span><br />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="ma-0 pa-0"></v-divider>
          </span>
          <v-card-text class="px-3 py-5" style="color:#101318; background: #F9FAFB;">
<!--                <v-row>-->
<!--                    <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">-->
<!--                        <strong>Total Payment Due</strong>-->
<!--                    </v-col>-->
<!--                    <v-col cols="2" class="pa-0 pb-1 pl-3 text-right pr-8">-->
<!--                        <span v-if="invoices.length !== 0"><strong>{{calculateTotalDue | formatMoney}}</strong></span>-->
<!--                        <span v-if="invoices.length === 0"><strong>N/A</strong></span>-->
<!--                    </v-col>-->
<!--                </v-row>-->
              <v-row>
                  <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">
                      <span class="text-capitalize font-weight-medium">Payment Total</span>
                  </v-col>
                  <v-col cols="2" class="pa-0 pb-1 pl-3 pr-6 text-right">
                      <span class="font-weight-medium">{{payment.amount | formatMoney}}</span>
                  </v-col>
              </v-row>
              <v-row v-if="payment.method === 'cash'">
                  <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">
                      <span class="text-capitalize font-weight-regular">Cash Amount Tendered</span>
                  </v-col>
                  <v-col cols="2" class="pa-0 pb-1 pl-3 pr-6 text-right">
                      <span class="font-weight-regular">{{payment.amount_tendered | formatMoney}}</span>
                  </v-col>
              </v-row>
              <v-row v-if="payment.method === 'cash'">
                  <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">
                      <span class="text-capitalize font-weight-regular">Change</span>
                  </v-col>
                  <v-col cols="2" class="pa-0 pb-1 pl-3 pr-6 text-right">
                      <span class="font-weight-regular">{{(payment.amount_tendered - payment.amount) | formatMoney}}</span>
                  </v-col>
              </v-row>
              <v-row v-if="!isInterProperty">
                  <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">
                      <span class="text-capitalize font-weight-regular">Account Balance Due</span>
                  </v-col>
                  <v-col cols="2" class="pa-0 pb-1 pl-3 pr-6 text-right">
                      <span class="font-weight-regular">{{payment.account_balance | formatMoney}}</span>
                  </v-col>
              </v-row>
          </v-card-text>
        </v-card>
        <v-row v-if="payment && payment.LeaseAuction && payment.LeaseAuction.refund_id">
          <v-col class="pt-6">
            <v-card
              outlined
            >
              <v-card-title
                class="px-6 py-3"
                primary-title
              >
                <span class="subtitle-1 font-weight-medium">Refunds</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="px-1 py-1" style="color:#101318;">
                <v-row>
                  <v-col class="pr-2 pl-8">
                    <span class="text-caption text-capitalize font-weight-regular">Cleaning Deposit<span style="float:right" class="pr-6">{{payment.LeaseAuction.cleaning_deposit | formatMoney}}</span><br /></span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="px-3 py-5" style="color:#101318; background: #F9FAFB;">
                <v-row>
                    <v-col cols="10" class="pa-0 pb-1 pl-3 text-right">
                        <span class="text-capitalize font-weight-medium">Total Refunded</span>
                    </v-col>
                    <v-col cols="2" class="pa-0 pb-1 pl-3 pr-6 text-right">
                        <span class="font-weight-medium">{{payment.LeaseAuction.cleaning_deposit | formatMoney}}</span>
                    </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

</template>

<script type="text/babel">

  import Status from '../includes/Messages.vue';
  import Loader from '../assets/CircleSpinner.vue';
  import api from '../../assets/api.js'; 
  import { mapGetters } from 'vuex';
  export default {
      name: "ViewPaymentContent",
      data() {
          return {
              fromOnboarding: false,
              taxRate: 0,
          }
      },
      created(){
        this.getTaxRate();
      },
      computed:{
          ...mapGetters({
              isAdmin: 'authenticationStore/isAdmin'
          }),
          calculateTotalDue(){
            let total = [];

            Object.entries(this.invoices).forEach(([key, val]) => { total.push(val.total_due) });

            return total.reduce(function(total, num){ return total + num }, 0);
          },
          totalBalanceDue(){
            let total_due = this.invoices.reduce(function(total, inv){ return total + inv.balance }, 0);
            return total_due;
          },
      },
      methods:{
        getTaxRate(){
            api.get(this, api.SETTINGS_BILLING ).then(r => {
                this.taxRate = r.settings.taxRate;
            });
        },
        totalPayments(invoice){
          let appliedPayments =  this.payment?.AppliedPayments || [];
          if(this.isInterProperty){
            appliedPayments = this.payment?.InterPropertyPayment?.appliedPayments || [];
          }
          if(appliedPayments?.length > 0){
            let p = appliedPayments.filter(x=>x.invoice_id === invoice.id)
            return p.length ? p[0].amount : 0
          } else {
            return 0
          }
        },
        remainingAmount(invoice) {
          return invoice.total_due - this.totalPayments(invoice)
        },
        getAppliedPaymentsToProperty() {
          let interPropertyPaymentAmount = 0;
          this.invoices.map(i => {
            if(i.Lease?.Property?.id == this.payment.Property.id) {
              interPropertyPaymentAmount += i.total_payments;
            }
          });
          return interPropertyPaymentAmount;
        },
        formatAcceptedBy(name){
          const alphabetRegex = /^[A-Za-z]/;
          const first = name.first && name.first.match(alphabetRegex);
          const last = name.last && name.last.match(alphabetRegex);
          if(this.payment.useManagerInitials){
            return `${first ? first[0].toUpperCase() + '.' : ''}${last ? last[0].toUpperCase() + '.' : ''}`;
          } else {
            return `${first} ${last}`;
          }
        }
      },
      components: {
          Status,
          Loader
      },
      props: {
        'invoices': {},
        'payment': {},
        'isInterProperty': {
          type: Boolean,
          default: false
        },
        'isGuestUser': {
          type: Boolean,
          default: false
        }
      }
  }

</script>
