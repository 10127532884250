export default [
  {
    name: "MerchandiseManagement",
    path: '/merchandise-management',
    component: () => import('../components/merchandise_management/index.vue'),
    meta: {
      requiresAuth: true,
      hasAccess: ['admin'],
      layout: 'master',
      re_fetch:true
    }
  }
]
