<template>
  <hb-modal
    v-model="notificationModal"
    size="medium"
    :title="notificationTitle"
    :footerOff="true"
    v-bind="$attrs"
    v-on="$listeners"
    confirmation
    @close="notificationModal = false"
    show-help-link
  >
    <template v-slot:content>
      <div class="px-6 py-4">
        <div class="pb-2">
          {{notificationMessage}}
        </div>
      </div>
    </template>
  </hb-modal>
</template>
<script type="text/babel">

  export default {
    name: "PaymentsNotificationModal",
    data: function() {
      return {
        lists: {
          manage_settings_bill: {
            title: "Unable to Edit Billing Setting",
            message: "You don’t have permission to edit your company’s billing settings. Reach out to your administrator to gain permission."
          },
          edit_invoices: {
            title: "Unable to Edit Invoices",
            message: "You don’t have permission to edit your company’s invoices. Reach out to your administrator to gain permission."
          },
          accept_payments: {
            title: "Unable to Accept Payment",
            message: "You don’t have permission to accept a payment. Reach out to your administrator to gain permission."
          },
          manage_partial_payments: {
            title: "Unable to Take Partial Payment",
            message: "You don’t have permission to take a partial payment. Reach out to your administrator to gain permission."
          },
          manage_delinquency_stages: {
            title: "Unable to Edit Delinquency Automations",
            message: "You don’t have permission to edit delinquency automations. Reach out to your administrator to gain permission."
          },
          manage_retail_products: {
            title: "Unable to Add or Edit a product",
            message: "You don’t have permission to add or edit a product. Reach out to your administrator to gain permission."
          },
          edit_bill_day: {
            title: "Unable to Edit Billing Day",
            message: "You don’t have permission to edit the tenant’s billing day. Reach out to your administrator to gain permission."
          },
          edit_autopay: {
            title: "Unable to Edit Autopay",
            message: "You don’t have permission to edit the tenant’s autopay. Reach out to your administrator to gain permission."
          },
          no_tax_rent: {
            title: "Unable to Edit Tax Exempt",
            message: "You don’t have permission to edit the tenant’s tax exempt. Reach out to your administrator to gain permission."
          }
        }
      };
    },
    computed: {
      notificationModal: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      notificationTitle(){
        if(this.lists[this.notificationType] && this.lists[this.notificationType]?.title){
          return this.lists[this.notificationType]?.title;
        } else {
          return '';
        }
      },
      notificationMessage(){
        if(this.lists[this.notificationType] && this.lists[this.notificationType]?.message){
          return this.lists[this.notificationType]?.message;
        } else {
          return '';
        }
      }
    },
    methods: {
      close(){
        this.notificationModal = false;
        this.$emit('close');
      },
    },
    props: {
      value:{
        type: Boolean,
        default: false
      },
      notificationType: {
        type: String,
        default: undefined
      }
    },
  }
</script>
