<template>
    <div id="file-uploads" class="">
      <v-sheet  class="chat-window-nav">
      <hb-tabs 
        background-color="#FFFFFF"
      >
        <v-tab
          v-for="menuOption in mainViewOptions"
          :value="menuOption"
          @click="mainViewChanged(menuOption)"
        >
          {{ menuOption.Unit.number }}
        </v-tab>
      </hb-tabs>

    </v-sheet>
      <div class="doc-list" style="height: 65vh" v-if=true >
        <v-card  class="mb-4" :class="{'mr-6' : showAside}" elevation="0">
          <v-data-table
              :headers="headers"
              :items="uploads"
              :loading="loadingDocuments"
              loading-text="Loading Documents..."
              class="hb-data-table"
              disable-pagination
              hide-default-footer
              :custom-sort="customSort"
              @click:row="onRowClick"
          >
            <template v-slot:item.space="{ item }">
                <div v-if="item.id" class="d-flex align-top">
                  <hb-unit-icon color="#101318" small class="pr-1" :type="getUnitTypeLabel(item)" />
                </div>
            </template>
            <template v-slot:item.filename="{ item }">
              <span v-if="item.id">
                {{ item.name }}
                <div class="hb-text-light" v-if="item.src && item.src.length > 0">
                  <span style="text-transform: uppercase" v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1]">
                    {{ item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1].trim() }}
                  </span>
                  <span v-if="item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1] && item.src.split('#').shift().split('?').shift().split('/').pop().split('.')[1].trim().length > 0 && item.size !== '0'">
                    -
                  </span>
                  <span v-if="item.size !== '0'">
                    {{item.size | formatFileSize}}
                  </span>
                </div>
              </span>
            </template>
            <template v-slot:item.type="{ item }">
              <span v-if="item.id">
                {{ item.DocumentType.name }}
              </span>
            </template>
            <template v-slot:item.uploaded="{ item }">
              <div class="py-3" v-if="item.id">
                <hb-status-general :status="item.status"></hb-status-general>
                <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].Contact" class="hb-text-light mt-1 mb-n1">{{item.signers[0].Contact.first}} {{item.signers[0].Contact.last}}</div>
                <div v-if="item.status === 'Signed' && item.signers[0] && item.signers[0].signed" class="hb-text-light mt-1 mb-n1">on {{item.signers[0].signed | formatDateTimeCustom('MMM DD, YYYY [@] h:mma')}}</div>
                <div v-else class="hb-text-light mt-1 mb-n1">{{item.upload_date | formatDateTimeCustom('MMM DD, YYYY [@] h:mma')}}</div>
              </div>
            </template>
          
            <template v-slot:item.checkbox="{ item }">
                <v-checkbox 
                  v-model="selectedoption"
                  :value="item"
                  hide-details
                  class="square-checkbox"
                ></v-checkbox>
            </template>
           
          </v-data-table>
        </v-card>
      </div> 
    </div>



</template>
<script type="text/babel">

    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    import Modal from '../assets/Modal.vue';
    import Sign from '../includes/SignDocuments.vue';
    import { EventBus } from '../../EventBus.js';
    import { mapGetters, mapMutations, mapActions } from 'vuex';
//    import PublicDocuments from '../leases/PublicDocuments.vue';
    import { notificationMixin } from  '@/mixins/notificationMixin.js'
    export default {
        name: 'Documents',
        data() {
            return {
              previouslySelected:"",
               selectedoption:"",
                link: null,
                signer: {},
                showDelete: false,
                showGenerate: false,
                documents: [],
                document: {},
                documentSpace: {},
                selected: {},
                hasSubmitted: false,
                uploads: [],
                send_document_id: null,
                completed: false,
                lease: {},
                headers: [
                    { text: '', value: 'checkbox', align: 'left', sortable: false, width: '50px' },                    
                    { text: 'File Name', value: 'filename', width: '120px' },
                    { text: 'Document Type', value: 'type', width: '100px' },
                    { text: 'Status', value: 'uploaded', width: '120px' },                   
                
                ],
                libraryHeaders: [
                    { text: 'Document Name', value: 'name', width: 640 },
                    { text: 'Document Type', value: 'type' },
                ],
                statuses: [
                    'error',
                    'not_processed',
                    'loading',
                    'ready',
                    'loaded',
                    'initiated',
                    'complete',
                    'finished',
                ],
                send_doc_data: {
                    message: '',
                    method: '',
                    contact: {},
                    docs: []
                },
                status_message:'',
                mainView: {
                  id: null,
                  Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                  }
                },           
                dateRange: '',
                customStartDate: '',
                customEndDate: '',
                todaysDate: '',
                loadingDocuments: false,
                allowScrollRequest: true,
                loadingDocumentTemplates: false,
                documentMethod: 'un-signed',
                documentType: { value: 'all'},
                isDocumentReset: false,
                document_generate_type: '',
                isDocumentTypeChanged: false,
                fetchUnSignedDocumentUsingScroll: false,
                documentText: 'Loading documents...'
            }
        },
        mixins: [ notificationMixin ],
        async created (){
          let data = this.propertyView;
                    this.fetchData();
          await this.fetchDocumentTypes();
          await this.fetchUnSignedDocumentTypes();
          EventBus.$on('document_signed', this.fetchData);
          this.todaysDate = moment().startOf('day').format('YYYY-MM-DD');
        },
      
        components:{
            Modal,
            Loader,
            Sign,
            Status,
        },
        computed:{
            ...mapGetters({
                isAdmin: 'authenticationStore/isAdmin',
                getUserData: 'authenticationStore/getUserData',
                getDocuments: 'documentsStore/getDocuments',
                getDocumentTypes: 'documentsStore/getDocumentTypes',
                getUnSignedDocumentTypes: 'documentsStore/getUnSignedDocumentTypes',
            }),
            getAllDocumentTypes() {
              if(this.getDocumentTypes.length && this.getUnSignedDocumentTypes.length) {
                let documentTypes = [];
                this.getDocumentTypes.map((type, index) => {
                  if(type.value !== 'all' )
                    documentTypes.push(type);
                });
                this.getUnSignedDocumentTypes.map((type, index) => {
                  if(type.value !== 'all' )
                    documentTypes.push(type);
                });
                return documentTypes;
              }
            },
            getDocumentType() {
              return (this.documentType.value != 'all') ? this.documentType : '';
            },
            mainViewOptions(){
                          var options = [];
              options.push({
                id: null,
                Unit: {
                  number: 'Tenant',
                  type: 'tenant',
                }
              });
            
              this.contactLeases.forEach(lease => {
                if(this.propertyView){
                  if(lease.Unit.property_id==this.propertyView){
                  options.push({
                  id: lease.id,
                  end_date: lease.end_date,
                  Unit: {
                    number: lease.Unit.number,
                    type: lease.Unit.type,
                  }
                });
                }
                }else{
                  options.push({
                  id: lease.id,
                  end_date: lease.end_date,
                  Unit: {
                    number: lease.Unit.number,
                    type: lease.Unit.type,
                  }
                });
                }                
              })            
              return options;
            }
        },
        methods: {
          onRowClick(item) {
            if(this.previouslySelected == item){
              this.selectedoption = ""
              this.previouslySelected = ""
              this.$emit('docSelectedemit',"")
            }else{
              
              this.selectedoption = item
              this.previouslySelected = item
              this.$emit('docSelectedemit',this.selectedoption)
            }
          },
          ...mapActions({
            fetchDocumentsData: 'documentsStore/fetchDocuments',
            fetchDocumentTypes: 'documentsStore/fetchDocumentTypes',
            fetchUnSignedDocumentTypes: 'documentsStore/fetchUnSignedDocumentTypes'
          }),
          ...mapMutations({
            setDocuments: 'documentsStore/setDocuments',
          }),
          
         
          customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index[0] === 'space') {
                        var fa = '';
                        if(a.id && a.unit_number){
                            fa = a.unit_number.toLowerCase();
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.unit_number){
                            fb = b.unit_number.toLowerCase();
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'filename') {
                        var fa = '';
                        if(a.id && a.name){
                            fa = a.name.toLowerCase();
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.name){
                            fb = b.name.toLowerCase();
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'type') {
                        var fa = '';
                        if(a.id && a.DocumentType && a.DocumentType.name){
                            fa = a.DocumentType.name.toLowerCase();
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.DocumentType && b.DocumentType.name){
                            fb = b.DocumentType.name.toLowerCase();
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'uploaded') {
                        var fa = '';
                        if(a.id && a.status){
                            fa = a.status.toLowerCase();
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && b.status){
                            fb = b.status.toLowerCase();
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                    if (index[0] === 'public') {
                        var fa = '';
                        if(a.id && a.private && a.private === true){
                            fa = 'no';
                        } else if(a.id && a.private && a.private === false){
                            fa = 'yes';
                        } else {
                            ''
                        }

                        var fb = '';
                        if(b.id && a.private && b.private === true){
                            fb = 'no';
                        } else if(b.id && a.private && b.private === false){
                            fb = 'yes';
                        } else {
                            ''
                        }
                        if (!isDesc[0]) {

                            if (fa > fb) {
                                return -1;
                            }
                            if (fa < fb) {
                                return 1;
                            }
                            return 0;
                        } else {

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        }
                    }
                });

                return items;
            },
            async fetchDocuments(documentGenerateType = false){
              this.loadingDocumentTemplates = true;
              if(this.documentMethod == 'un-signed' && !documentGenerateType) {
                this.fetchNonSignedDocuments();
                return;
              }
              let limit = 30;
              let offset = this.documents.length == 0 ? 1 : Math.ceil(this.documents.length / limit) + 1 ;
              let params = '';
              if(documentGenerateType) {
                params = `?limit=${limit}&offset=${offset}&type[]=${this.document_generate_type}`;
              } else if(this.getDocumentType != '') {
                params = `?limit=${limit}&offset=${offset}&type[]=${this.getDocumentType.value}`;
              } else {
                params = `?limit=${limit}&offset=${offset}&fetch_details=true`;
              }
              
              console.log("Before Request: ", moment().format('MMMM Do YYYY, h:mm:ss a'));
              let path = (this.propertyView && documentGenerateType) ? `&property_ids[]=${this.propertyView}` : '';
                api.get(this, api.DOCUMENT.slice(0, -1) + params + path).then(r => {

                  if(r.documents.length == 0) {
                    this.allowScrollRequest = false;
                    this.documentText = 'No data found.'
                  }else{
                    this.allowScrollRequest =  true;
                  }
                    this.tempDocs = [...this.documents, ...r.documents];
                    this.documents =  this.tempDocs;
                    this.loadingDocumentTemplates = false;

                    console.log("After Request: ", moment().format('MMMM Do YYYY, h:mm:ss a'));
                }).catch(err => {
                  this.showMessageNotification({description: err});
                  this.loadingDocumentTemplates = false;
                });
            },

            async fetchNonSignedDocuments() {
              if(!this.fetchUnSignedDocumentUsingScroll){
                await this.setDocuments([]);
                this.documentText = 'Loading documents...'
                this.fetchUnSignedDocumentUsingScroll = false
              }
              let limit = 30;
              let offset = this.getDocuments.length == 0 ? 1 : Math.ceil(this.getDocuments.length / limit) + 1 ;
              let documentTypes = '';
              if(this.getDocumentType != '') {
                documentTypes = `document_type=un-signed&type[]=${this.getDocumentType.value}`;
              } else {
                documentTypes = `document_type=un-signed`;
              }
              let path = (this.propertyView) ? `&property_ids[]=${this.propertyView}` : '';
              let data = {
                component: this,
                params: api.DOCUMENT.slice(0, -1) + `?limit=${limit}&offset=${offset}&${documentTypes}` + path
              }
              try {
                const currentDocuments = await this.fetchDocumentsData(data);
                if(currentDocuments.length === 0){
                  this.allowScrollRequest = false;
                  this.documentText = 'No data found.'
                }
                this.documents = this.getDocuments;
                this.loadingDocumentTemplates = false;
              } catch(err) {
                this.showMessageNotification({ description: err });
                this.loadingDocumentTemplates = false;
              } finally {
                this.loadingDocumentTemplates = false;
              }
            },


            async fetchData(lease, dateRange, customStartDate, customEndDate){
                            this.uploads = [];
              this.loadingDocuments = true;
              if( (!lease || (lease.id === null || !lease.id))){
                let r = await api.get(this, api.CONTACTS + this.contactid + '/uploads', {property_id: this.propertyView} );
                var mapUploads = r.uploads.map(u => {
                    if(u.uploaded_by){
                      u.status = 'Uploaded';
                    } else if(!u.signers || !u.signers.length){
                      u.status = 'Generated';
                    } else if(u.signers[0].signed){
                      u.status = 'Signed';
                    } else if(!u.signers[0].signed){
                      u.status = 'Ready to sign';
                    }

                    return u;
                });
                if(dateRange === 'Last 30 Days'){
                  this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed){
                      return moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    } else {
                      return moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    }
                  });

                } else if(dateRange === 'Custom'){
                  this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed){
                      return (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    } else {
                      return (moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.upload_date).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    }
                  });
                } else {
                  this.uploads = mapUploads;
                }
              } else{  
                let r = await api.get(this, api.CONTACTS + this.contactid + '/uploads', {property_id: this.propertyView, lease_id: lease.id} );
                var mapUploads = r.uploads.map(u => {
                    if(u.uploaded_by){
                      u.status = 'Uploaded';
                    } else if(!u.signers || !u.signers.length){
                      u.status = 'Generated';
                    } else if(u.signers[0].signed){
                      u.status = 'Signed';
                    } else if(!u.signers[0].signed){
                      u.status = 'Ready to sign';
                    }

                    return u;
                });
                if(dateRange === 'Last 30 Days'){
                  this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed){
                      return moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    } else {
                      return moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment().startOf('day').subtract(30, 'days');
                    }
                  });

                } else if(dateRange === 'Custom'){
                  this.uploads = mapUploads.filter(upload => {
                    if (upload.status === 'Signed' && upload.signers[0] && upload.signers[0].signed){
                      return (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.signers[0].signed).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    } else {
                      return (moment(upload.upload_date).subtract(8, 'hours').startOf('day') >= moment(customStartDate).startOf('day')) && (moment(upload.upload_date).subtract(8, 'hours').startOf('day') <= moment(customEndDate).startOf('day'));
                    }
                  });
                } else {
                  this.uploads = mapUploads;
                }
              }
              this.loadingDocuments = false;
            },

            async resetFilters(){
              this.dateRange = '';
              this.customStartDate = '';
              this.customEndDate = '';
              await this.setDocuments([]);
              this.documentText = 'Loading documents...'
              this.documents = [];
              await this.fetchData(this.mainView);
            },
            async mainViewChanged(x){
              this.mainView = x
              this.uploads = [];
              await this.resetFilters();
            },
           
        },
        props: [ 'contact', 'showAside', 'contactLeases', 'view', 'propertyView','contactid' ],
        watch: {
            document(){
                if(this.document.id){
                    this.hasSubmitted = false;
                }
            },
            async view(){
              if(this.view === 'tenantdocuments'){
                await this.resetFilters();
              } else {
                this.isDocumentReset = true;
              }
            },
            customStartDate(){
              if(this.customStartDate === ''){
                this.customEndDate = '';
              }
            },
            async propertyView(){
              this.mainView = {
                id: null,
                Unit: {
                  number: 'Tenant',
                  type: 'tenant',
                }
              };
              if(this.propertyView){
                await this.resetFilters();
                this.isDocumentReset = true;
              }
            }
        }
    }
</script>

<style lang="scss">
    .select-filter {
      border: 1px solid #DFE3E8;
      border-radius:4px;
      width:175px;
      height: 40px;
      background:white;
    }

    .select-filter:hover {
      border: 1px solid #C4CDD5;
      cursor: pointer;
    }

    .select-filter.download-center-filters-active {
        border: 2px solid #00848E;
    }

    .document-filters-footer {
      border: 1px solid #dfe3e8;
      border-top: none;
    }

</style>


