export default [
  {
    name: "NationalAccountLeaseDetails",
    path: "/national-account/:contact_id",
    component: () => import("../components/contacts/NationalAccount/View.vue"),
    meta: {
      requiresAuth: true,
      hasAccess: ["admin"],
      layout: "master",
      re_fetch: true,
    },
      children:[
        {
          path: '',
          name: 'NationalAccountOverView',
          component: () => import('../components/contacts/NationalAccount/OverView.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'account-profile',
          name: 'NationalAccountProfile',
          component: () => import('../components/contacts/NationalAccount/Profile.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'account-information',
          name: 'AccountInformation',
          component: () => import('../components/contacts/NationalAccount/AccountInformation.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'communication',
          name: 'Communication',
          component: () => import('../components/contacts/NationalAccount/Communication.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'files',
          name: 'NationalDocuments',
          component: () => import('../components/contacts/NationalAccount/NationalDocuments.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        {
          path: 'transaction-history',
          name: 'NationalAccountPayment',
          component: () => import('../components/contacts/NationalAccount/TransactionHistory.vue'),
          meta: {
            requiresAuth: true,
            hasAccess: ['admin'],
            layout: 'master',
            re_fetch:true
          }
        },
        // {
        //   path: 'payment-methods',
        //   name: 'ContactPaymentMethods',
        //   component: () => import('../components/contacts/PaymentMethods.vue'),
        //   meta: {
        //     requiresAuth: true,
        //     hasAccess: ['admin'],
        //     layout: 'master',
        //     re_fetch:true
        //   }
        // },
        // {
        //   path: 'gate-access',
        //   name: 'ContactAccessControl',
        //   component: () => import('../components/contacts/Access.vue'),
        //   meta: {
        //     requiresAuth: true,
        //     hasAccess: ['admin'],
        //     layout: 'master',
        //     re_fetch:true
        //   }
        // },
        // {
        //   path: 'payment-cycles',
        //   name: 'ContactPaymentCycles',
        //   component: () => import('../components/contacts/PaymentCyclesList.vue'),
        //   meta: {
        //     requiresAuth: true,
        //     hasAccess: ['admin'],
        //     layout: 'master',
        //     re_fetch:true
        //   }
        // }
      ]
  },
];
