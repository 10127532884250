<template>

<div>
    <hb-modal v-model="dialog" show-help-link size="large" :title="title" @close="$emit('close')">
        <template v-slot:subheader v-if="!isEditProduct">
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            Complete the required fields to add a new product to the list of available products for the selected properties.
        </template>
        <template v-slot:content>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name) && isEditProduct" :message="errorGet($options.name)" status="error" class="mx-6 mt-3"></status>
            <v-row class="ma-0 pa-0 no-top-border" v-if="!isPropertyLevel || productType">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Name</div>
                </v-col>
                <v-col md="8" class="pl-1">
                    <v-text-field
                        :readonly="isPropertyLevel && isEditProduct"
                        placeholder="Enter Name"
                        v-validate="'required|max:45'"
                        required
                        id="name"
                        name="name"
                        v-model="product.name"
                        solo
                        flat
                        single-line
                        :error-messages="errors.first('name')"
                        :hide-details="!errors.has('name')"
                    > </v-text-field>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Description</div>
                </v-col>
                <v-col md="8" class="pl-1">
                    <v-textarea
                        placeholder="Enter Description"
                        rows="2"
                        v-validate="'max:1000'"
                        id="description"
                        name="description"
                        v-model="product.description"
                        single-line
                        solo
                        flat
                        :error-messages="errors.first('description')"
                        :hide-details="!errors.has('description')"
                    > </v-textarea>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && !productType">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Type</div>
                </v-col>
                <v-col md="7" class="pl-1">
                    <v-select
                        solo
                        flat
                        hide-details
                        attach
                        label="Select a type"
                        :items="product_types"
                        v-model="product.default_type"
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ item | capitalize }}
                        </template>
                        <template v-slot:item="{ item, index }">
                            {{ item | capitalize }}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0 ">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2"> {{ productType == 'rent' ? 'Taxable' : 'Amount' }}</div>
                </v-col>
                <v-col class="pl-1">
                    <v-row v-if="is_rate_type">
                        <v-col md="8" class="py-0">
                            <v-select
                                solo
                                flat
                                hide-details
                                attach
                                label="Select Rate Type"
                                :items="rateTypes"
                                item-text="label"
                                item-value="value"
                                v-model="product.amount_type"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <div v-if="product.amount_type === 'scheduled'" >
                        <v-row v-for="(item, index) in product.Rules" :key="index">
                            <v-col md="2" class="py-1 pr-0" style="max-width: 80px;">
                                <v-select
                                    solo
                                    flat
                                    hide-details
                                    :items="priceTypes"
                                    item-text="label"
                                    item-value="value"
                                    v-model="item.type"
                                ></v-select>
                            </v-col>
                            <v-col md="2" class="pa-0" style="max-width: 72px;">
                                <v-text-field
                                    placeholder="Amount"
                                    :hide-details="!errors.has('price_' + index)"
                                    single-line
                                    v-model="item.price"
                                    v-validate="'required|decimal:2|max_value:9999|min_value:0'"
                                    data-vv-as="amount"
                                    :name="'price_' + index"
                                    :id="'price_' + index"
                                    :error-messages="errors.first('price_' + index)"
                                    class="pa-0"
                                    > </v-text-field>
                            </v-col>
                            <v-col md="5" class="pr-0" style="max-width: 210px;">
                                <template v-if="index == product.Rules.length-1">
                                    if rate is greater than {{(product.Rules[index-1] && product.Rules[index-1].rent_threshold) ? product.Rules[index-1].rent_threshold : 0 | formatMoney}}
                                </template>
                                <template v-else>
                                    if rate is less than or equal to
                                </template>
                            </v-col>
                            <v-col md="3" class="pa-0" style="max-width: 110px;" v-if="product.Rules.length-1 != index">
                                <v-text-field
                                    placeholder="Amount"
                                    hide-details
                                    :class="{'custom-field-error' : errors.first(`${'threshold_' + index}`)}"
                                    single-line
                                    prepend-icon="mdi-currency-usd"
                                    v-model="item.rent_threshold"
                                    v-validate="`required|decimal:2|max_value:9999|min_value:${getMinValueForAmount(index)}`"
                                    data-vv-as="amount"
                                    :name="'threshold_' + index"
                                    :id="'threshold_' + index"
                                    :error-messages="errors.collect(`${'threshold_' + index}`)"
                                    class="pa-0"
                                    > </v-text-field>
                            </v-col>
                            <v-col class="pl-6" v-if="product.Rules.length-1 !== index && product.Rules.length > 2">
                                <a class="hb-link" @click="removeFeeSchedule(index)">Remove</a>
                            </v-col>
                        </v-row>
                    </div>
                    <v-row v-if="product.amount_type === 'scheduled'">
                        <v-col class="pl-6">
                            <a class="hb-link" @click="addFeeSchedule">+ Add New Fee Schedule</a>
                        </v-col>
                    </v-row>
                    <v-row v-if="productType != 'rent' && is_enter_amount">
                        <v-col md="8" class="pl-5 py-0" style="max-width: 352px">
                            <v-text-field
                                placeholder="Enter Amount"
                                single-line
                                prepend-icon="mdi-currency-usd"
                                v-model="product.price"
                                v-validate="isVariableAmount ? 'decimal:2|max_value:9999|min_value:.01' : 'required|decimal:2|max_value:9999|min_value:0'"
                                name="price"
                                id="price"
                                class="pa-0"
                                :error-messages="errors.first('price')"
                                :hide-details="!errors.has('price')"
                            > </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="productType != 'rent' && is_enter_percentage">
                        <v-col md="8" class="pl-5 py-0" style="max-width: 352px">
                            <v-text-field
                                placeholder=" Enter Percentage"
                                single-line
                                prepend-icon="mdi-percent-outline"
                                v-model="product.price"
                                v-validate="'required|decimal:2|max_value:9999|min_value:0'"
                                name="percentage"
                                id="percentage"
                                class="pa-0"
                                :error-messages="errors.first('percentage')"
                                :hide-details="!errors.has('percentage')"
                            > </v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row v-if="!isPropertyLevel || ( isPropertyLevel && productType != 'fees' )">
                        <v-col class="pl-5 py-0">
                            <v-checkbox
                                hide-details
                                v-model="product.taxable"
                                id="checkbox-taxable"
                                name="checkbox-taxable"
                                label="Is the product taxable?">
                            </v-checkbox>
                        </v-col>
                    </v-row> -->
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Taxable</div>
                </v-col>
                <v-col md="9" class="pl-3">
                    <v-radio-group v-model="product.taxable" row class="mt-0 pt-0" hide-details>
                        <v-radio label="No" :value="0"></v-radio>
                        <v-radio class="pl-3" label="Yes" :value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            
           <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && product.default_type == 'rent' || product.default_type == 'late'" 
            v-show="!(productLevel == 'Property' && isEditProduct == true)" >
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Category</div>
                </v-col>
                <v-col class="pl-1">
                    <v-row>
                        <v-col md="8" class="py-0">
                            <v-select
                                name="category_type"
                                :error-messages="errors.first('category_type')"
                                :hide-details="!errors.has('category_type')"
                                data-vv-as="category type"
                                solo
                                flat
                                attach
                                label="Select Category Type"
                                :items="product.default_type == 'late' ? categoryTypesFeesWithCondition : categoryTypesRent"
                                item-text="label"
                                item-value="value"
                                v-model="product.category_type"
                                v-validate="'required'"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="productType == 'fees'">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Recurring</div>
                </v-col>
                <v-col md="9" class="pl-3">
                    <v-radio-group v-model="product.recurring" row class="mt-0 pt-0" hide-details>
                        <v-radio label="No" :value="0"></v-radio>
                        <v-radio class="pl-3" label="Yes" :value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="productType">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Prorate In</div>
                </v-col>
                <v-col md="9" class="pl-3">
                    <v-radio-group v-model="product.prorate" row class="mt-0 pt-0" hide-details>
                        <v-radio label="No" :value="0"></v-radio>
                        <v-radio class="pl-3" label="Yes" :value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="productType">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Prorate Out</div>
                </v-col>
                <v-col md="9" class="pl-3">
                    <v-radio-group v-model="product.prorate_out" row class="mt-0 pt-0" hide-details>
                        <v-radio label="No" :value="0"></v-radio>
                        <v-radio class="pl-3" label="Yes" :value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0  no-top-border" v-if="product.has_inventory && isPropertyLevel && !productType">

                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Inventory</div>
                </v-col>
                <v-col md="9" class="pl-1">
                    <v-text-field
                        solo
                        flat
                        placeholder="Enter Item Inventory"
                        single-line
                        v-validate="'required'"
                        v-model="product.inventory"
                        name="inventory"
                        id="inventory"
                        class="pa-0 mt-0 pb-1"
                        :error-messages="errors.first('inventory')"
                        :hide-details="!errors.has('inventory')"
                    > </v-text-field>
                </v-col>
            </v-row>
            <v-row class="top-border ma-0 pa-0" v-if="available_accounts.length > 0 && product.default_type !== 'rent'">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">GL Account</div>
                </v-col>
                <v-col md="9" class="pl-4">
                    <hb-autocomplete
                        name="gl_account_id"
                        :disabled="!hasPermission('assign_gl_accounts')"
                        label="Select an Account"
                        :items="available_accounts"
                        :clearable="false"
                        v-validate="`${incomeAccountRequired ?  'required' : ''}`"
                        :error-messages="errors.first('gl_account_id') ? 'Income account is required as accounting service is enabled': ''"
                        :hide-details="!errors.has('gl_account_id')"
                        data-vv-as="gl account"
                        item-value="id"
                        item-text="name"
                        v-model="product.income_account_id">
                        <template v-slot:selection="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                    </hb-autocomplete>
                </v-col>
            </v-row>

            <!-- <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && accounts.length > 0">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Expense Account</div>
                </v-col>
                <v-col md="9" class="pl-1">
                    <v-select
                        :disabled="!hasPermission('assign_gl_accounts')"
                        solo
                        flat
                        hide-details
                        attach
                        label="Select an Account"
                        :items="expense_accounts"
                        item-value="id"
                        v-model="product.expense_account_id" >
                        <template v-slot:selection="{ item }">
                        {{item.code}} : {{item.name}}
                        </template>
                        <template v-slot:item="{ item }">
                        {{item.code}} : {{item.name}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && accounts.length > 0">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Liability Account</div>
                </v-col>
                <v-col md="9" class="pl-1">
                    <v-select
                        :disabled="!hasPermission('assign_gl_accounts')"
                        solo
                        flat
                        hide-details
                        attach
                        label="Select an Account"
                        :items="liability_accounts"
                        item-value="id"
                        v-model="product.liability_account_id" >
                        <template v-slot:selection="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && accounts.length > 0">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Concessions Accounts</div>
                </v-col>
                <v-col md="9" class="pl-1">
                    <v-select
                        :disabled="!hasPermission('assign_gl_accounts')"
                        solo
                        flat
                        hide-details
                        attach
                        label="Select an Account"
                        :items="concession_accounts"
                        item-value="id"
                        v-model="product.concession_account_id" >
                        <template v-slot:selection="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                        <template v-slot:item="{ item }">
                            {{item.code}} : {{item.name}}
                        </template>
                    </v-select>
                </v-col>
            </v-row> -->

            <v-row class="top-border ma-0 pa-0" v-if="!isPropertyLevel && !isOPS">
                <v-col md="3" class="key-label pl-4 pt-2">
                    <div class="pt-2">Enable Properties</div>
                </v-col>
                <v-col md="7" class="pl-3 scrollable-div">
                    <div class="overflow-text" v-for="(item, index) in propertiesList" :key="index">
                        <v-checkbox
                            hide-details
                            :label="item.name"
                            v-model="productProperties"
                            :value="item.id"
                        />
                    </div>
                </v-col>
            </v-row>
        </template>

        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save"><template v-if="isEditProduct">Edit</template><template v-else>Add</template></hb-btn>
                <span v-show="isLoading($options.name)">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
        </template>
    </hb-modal>

    <income-account-alert
    v-if="showAlertDialog && !isPropertyLevel"
    v-model="showAlertDialog"
    @confirm="confirmAlert"
    @close="showAlertDialog = false">
    </income-account-alert>
</div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import IncomeAccountAlert from './IncomeAccountAlertModal/IncomeAccountAlert.vue'

    // import { mixin as clickaway } from 'vue-clickaway';
    import { mapActions, mapGetters } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';

    export default {
        name: "EditProduct",
        mixins: [ notificationMixin ],
        data() {
            return {
                open: '',
                product:{
                    name:'',
                    taxable: 0,
                    price: '',
                    percentage: '',
                    amount_type: 'fixed',
                    category_type: null,
                    description: '',
                    type:'product',
                    default_type: 'product',
                    has_inventory: false,
                    inventory : 0,
                    income_account_id: '',
                    expense_account_id: '',
                    concession_account_id: '',
                    liability_account_id: '',
                    sku: '',
                    recurring: 0,
                    prorate: 0,
                    prorate_out: 0,
                    Rules: [
                        {id: null, type: 'dollar', price: null, rent_threshold: null},
                        {id: null, type: 'dollar', price: null, rent_threshold: null}
                    ],
                },
                accounts: [],
                account_subtypes: [],
                product_types: ['rent','product','late','security','utility','insurance'],
                isEditProduct : false,
                showDialog : true,
                isPropertyLevel : false,
                isCorpLevel : false,
                productProperties: [],
                // rateTypes: [
                //     {label: 'Fixed Rate', value: 'fixed'},
                //     {label: 'Variable Rate', value: 'variable'},
                //     {label: 'Fee Schedule', value: 'scheduled'}
                // ],
                priceTypes: [
                    {label: '$', value: 'dollar'},
                    {label: '%', value: 'percent'}
                ],
                categoryTypesFees: [
                    {label: 'Service', value: 'service'},
                    {label: 'Move In', value: 'movein'},
                    {label: 'Move-Out', value: 'moveout'},
                    {label: 'Delinquency', value: 'delinquency'},
                    {label: 'Transfer', value: 'transfer'}
                ],
                categoryTypesRent: [
                    {label: 'Rent', value: 'rent'},
                    {label: 'Rent Adjustment', value: 'adjustment'}
                ],
                showAlertDialog: false,
                alertConfirmed : false,

            }
        },
        components:{
            Loader,
            Status,
            IncomeAccountAlert
        },
        props:[
            'selected',
            'type',
            'productLevel',
            'facilityID',
            'productType',
            'value',
            'isOPS'
        ],
        async created(){

            if ((this.productLevel && this.productLevel == 'Property')) {
                    this.isPropertyLevel = true;
                    if (this.isOPS) {
                        this.isPropertyLevel = false;
                    }
            }


            if(this.selected && this.selected.id){
                this.isEditProduct = true;
                if ((this.productLevel && this.productLevel == 'Property') || this.facilityID) {
                    this.isPropertyLevel = true;
                    this.product = JSON.parse(JSON.stringify(Object.assign({}, this.selected)));
                    this.product.Rules = this.product.Rules.length ? this.product.Rules : [
                        {id: null, type: 'dollar', price: null, rent_threshold: null},
                        {id: null, type: 'dollar', price: null, rent_threshold: null}
                    ];
                } else {
                    this.fetchProductDetail();
                }
            }

            if (this.productType == 'fees') {
                this.product.default_type = 'late'
            }
            if (this.productType == 'rent') {
                this.product.default_type = 'rent'
            }

            await this.fetchAccounting();
            await this.getAccountingType();
            // if(this.productType == 'product')   await this.getAccountingType({ isProduct : true }); 
            // if(this.productType == 'fees')  await this.getAccountingType({ isFee : true });
        },
        computed:{
            ...mapGetters({
                propertiesList: 'propertiesStore/filtered',
                onboardingData: "onboardingTabsStore/getOnboardingData",
                hasPermission: 'authenticationStore/rolePermission',
                incomeAccountRequired: 'accountingStore/incomeAccountRequired',
                recordCountWithNullIncomeAccount: 'accountingStore/recordCountWithNullIncomeAccount'
            }),
            income_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'income');
            },
            expense_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'expense');
            },
            liability_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'liability');
            },
            concession_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.account_subtype.toLowerCase() === 'concessions');
            },
            available_accounts(){
              return [...this.income_accounts,...this.expense_accounts,...this.liability_accounts];
            },
            title(){
                if (this.productType == 'fees'){
                    if(this.isEditProduct){
                        return 'Edit Fee';
                    } else {
                        return 'Add Fee';
                    }
                }
                else if (this.productType == 'rent'){
                    if(this.isEditProduct){
                        return 'Edit Rent';
                    } else {
                        return 'Create Rent';
                    }
                }
                else {
                    if(this.isEditProduct){
                        return 'Edit Product';
                    } else {
                        return 'Add Product';
                    }
                }
            },
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            is_enter_amount(){
                return this.product.amount_type === 'fixed' || this.product.amount_type === 'variable'
            },
            is_enter_percentage(){
                return this.product.amount_type === 'percentage'
            },
            is_rate_type() {
                return this.productType === 'fees' || this.productType === 'product'
            },
            rateTypes(){
                let items = [
                    {label: 'Fixed Rate', value: 'fixed'},
                    {label: 'Percentage', value: 'percentage'},
                    {label: 'Variable Rate', value: 'variable'},
                    ...(this.productType === 'fees' ? [{label: 'Fee Schedule', value: 'scheduled'}] : [])
                ]
                if (this.productType === 'product') {
                    return items.filter(item => item.value !== 'percentage')
                }
                return items;
            },
            isVariableAmount() {
                return this.product.amount_type === 'variable';
            },

            // we need this function bcz, if amount_type is scheduled in that case category will be delinquency only not others. 
            categoryTypesFeesWithCondition: function() {
                return this.product.amount_type !== "scheduled" ? this.categoryTypesFees : [{label: 'Delinquency', value: 'delinquency'}];
            }

        },
        methods:{
            ...mapActions({
                getAccountingType : 'accountingStore/getAccountingType',
             }),

            addFeeSchedule() {
                this.product.Rules.push({id: null, type: 'dollar', price: null, rent_threshold: null});
            },
            removeFeeSchedule(index) {
                this.product.Rules.splice(index, 1 )
            },
            fetchProductDetail(){
                api.get(this, api.PRODUCTS + this.selected.id).then(r => {
                    this.product = JSON.parse(JSON.stringify(Object.assign({}, r.product)));
                    this.product.Rules = r.product.Rules.length ? r.product.Rules : [
                        {id: null, type: 'dollar', price: null, rent_threshold: null},
                        {id: null, type: 'dollar', price: null, rent_threshold: null}
                    ];
                    this.productProperties = r.product.Properties.map(val => val.id);
                });
            },
            async fetchAccounting(){
                let p = await api.get(this, api.ACCOUNTING + 'accounts');
                this.accounts = p.accounts || [];
            },


            confirmAlert() {
                this.alertConfirmed = true;
                this.showAlertDialog = false;
                this.save()
            },

            save(){
                this.$validator.validateAll().then((status) => {
                    if(!status) throw "error";


                    // if(this.incomeAccountRequired && this.recordCountWithNullIncomeAccount && !this.alertConfirmed && !this.isPropertyLevel) {
                    //     // show modal
                    //     this.showAlertDialog = true;
                    //     return
                    // }
                    
                        // Amount field validation under fee schedule.
                        let amountErrors = this.errors.items.filter( item => item.field.indexOf('threshold_') !== -1 );
                        if( amountErrors && amountErrors.length > 0) {
                            amountErrors.forEach(err => {
                                let index = err.field.split('_')[1];
                                err.msg = (this.getMinValueForAmount(index) > 0) ? 'you can’t have a smaller amount than the above cell. Please write a bigger number.'  : err.msg;
                                return err;
                            });
                            // TODO - show all field validation errors as notification toast.
						    this.showMessageNotification({id: this.$options.name, list: [amountErrors]});
                        }
                        
                        if (!status) {
                            throw "error";
                        }
                    
                    if(this.product.type == undefined){
                        this.product.type = 'product';
                    }

                    let data = {
                        name: this.product.name,
                        taxable: !!this.product.taxable,
                        amount_type: this.product.amount_type,
                        price: this.is_enter_amount ? this.product.price : this.is_enter_percentage ? this.product.price : 0,
                        Rules: this.product.amount_type == 'scheduled' ? this.product.Rules : null,
                        description: this.product.description,
                        type: this.product.type,
                        category_type: this.product.category_type,
                        default_type: this.product.default_type,
                        income_account_id: this.product.income_account_id,
                        expense_account_id: this.product.expense_account_id,
                        concession_account_id: this.product.concession_account_id,
                        liability_account_id: this.product.liability_account_id,
                        has_inventory: !!this.product.has_inventory,
                        sku: this.product.sku,
                        Properties: this.productProperties && this.productProperties.map(val => {
                            return {id: val};
                        }),
                        prorate : this.product.prorate,
                        prorate_out : this.product.prorate_out,
                        recurring : this.product.recurring

                    }
                    // added by BCT
                    if(this.isOPS && !this.isEditProduct && this.onboardingData.propertyData.length > 0){
                      data.Properties = [{id: this.onboardingData.propertyData[0].property_id}]  
                    } 
                    let propertyLevelData = {};

                    propertyLevelData.taxable = !!this.product.taxable;
                    if (this.product.has_inventory) {
                        propertyLevelData.inventory = this.product.inventory
                    }

                    propertyLevelData.price = this.is_enter_amount ? this.product.price : this.is_enter_percentage ? this.product.price : 0,
                    propertyLevelData.Rules = this.product.amount_type == 'scheduled' ? this.product.Rules : null,
                    propertyLevelData.amount_type = this.product.amount_type,
                    propertyLevelData.recurring = this.product.recurring;
                    propertyLevelData.prorate = this.product.prorate;
                    propertyLevelData.prorate_out = this.product.prorate_out; 
                    propertyLevelData.income_account_id =  this.product.income_account_id;

                    console.log("PRODUCT", data);
                    console.log("propertyLevelData->", propertyLevelData);


                    if(this.selected && this.selected.id){
                        if ((this.productLevel && this.productLevel == 'Property') || this.facilityID) {
                            let path = api.PROPERTIES + this.facilityID + '/products/'
                            api.put(this, path + this.selected.id, propertyLevelData).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', data.name, true);
                            });
                        } else {
                            api.put(this, api.PRODUCTS + this.selected.id, data).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', data.name, true);
                            }).catch(error => {
                                this.showMessageNotification({ description: error });
                            });
                        }
                    } else {
                        api.post(this, api.PRODUCTS,data).then(r => {
                            this.$emit('refetch', r.product.id);
                            this.$emit('save', r.product);
                            this.$emit('close');
                            this.$emit('showSuccess', data.name);
                        }).catch(error => {
                            this.showMessageNotification({ description: error });
                        });
                    }

                }).catch(err => {
                    this.showMessageNotification({ id: this.$options.name, type: "error", description: "You have errors on your form.  Please fix them before continuing"});
                });
            },
            away(){
                return this.openSuggestion = false;
            },
            closeWindow(){
              this.showDialog = false;
              setTimeout(() => {
                this.$emit('close');
              }, 200);
            },
            /**
             * To get the min value for amount field in fee schedule,
             * should be greater than the previous row.
             */
            getMinValueForAmount(index) {
                if(this.product.Rules && index > 0 && this.product.Rules[index-1].rent_threshold && this.product.Rules[index].rent_threshold) {
                    return parseInt(this.product.Rules[index-1].rent_threshold) + 1;
                }
                return 0;
            }

        },
        // mixins: [ clickaway ]
    }


</script>

<style scoped>
    .ma-0 pa-0{
        margin-left: -25px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101318;
      max-width: 150px;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .overflow-text {
        overflow-wrap: anywhere;
    }
    .scrollable-div{
        max-height: 350px;
        overflow: auto;
    }
</style>
