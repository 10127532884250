<template>
    <div style="width:125px;">
        <v-select :items="sortedSpaceOptions" item-text="Unit.number" item-value="id" return-object background-color="white"
            outlined dense hide-details :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
            v-model="mainView" height="40" id="mainView" class="vuetify-input-fieldset-fix">
            <template v-slot:selection="data">
                <div class="hb-input-overflow d-flex align-center">
                    <hb-icon 
                        :color="leaseSelectionColor(data.item)" 
                        class="pr-1"
                        v-if="data.item.Unit.type === 'tenant'" 
                        mdi-icon="mdi-account"
                    ></hb-icon>

                    <hb-unit-icon 
                        :color="leaseSelectionColor(data.item)" 
                        class="pr-1" 
                        v-else
                        :type="getUnitTypeLabel(data.item.Unit)"
                    />
                    
                    <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>

                </div>
            </template>
            <template v-slot:item="data">
                <hb-icon 
                    :color="leaseSelectionColor(data.item)" 
                    class="pr-1"
                    v-if="data.item.Unit.type === 'tenant'" 
                    mdi-icon="mdi-account"
                ></hb-icon>

                <hb-unit-icon 
                    :color="leaseSelectionColor(data.item)" 
                    class="pr-1" 
                    v-else
                    :type="getUnitTypeLabel(data.item.Unit)"
                />
                
                <span :class="leaseSelectionClass(data.item)">{{ data.item.Unit.number }}</span>

            </template>
        </v-select>
    </div>
</template>

<script>

export default {
    name: 'SpaceSelectionDropdown',
    props: ['mainViewOptions','setSelection','resetFilters'],
    data() {
        return {
            sortedSpaceOptions:[],
            mainView: {
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            },
            activeLeaseCount:0
        };
    },
    mounted() {
        this.sortSpaceByLease(JSON.parse(JSON.stringify(this.mainViewOptions)))
    },
    watch: {
        mainView() {
            this.$emit('spaceSelectionChanged', this.mainView)
        },
        mainViewOptions(newValue){
            this.sortSpaceByLease(JSON.parse(JSON.stringify( newValue)))
        },
        // just for tenant documents INC-7950
        resetFilters() {
            this.mainView = {
                id: null,
                Unit: {
                    number: 'Tenant',
                    type: 'tenant',
                }
            }
        }
    },

    methods: {
        isLeaseEnded(lease) {
            if (lease.end_date?.length) {
                return true;
            }

            return false;
        },
        leaseSelectionColor(lease) {
            return this.isLeaseEnded(lease) ? '#637381' : '#101318';
        },
        leaseSelectionClass(lease) {
            return this.isLeaseEnded(lease) ? 'hb-text-night-light' : 'hb-text-night';
        },
        sortSpaceByLease(newValue) {
            this.sortedSpaceOptions = newValue
            this.sortedSpaceOptions = this.sortedSpaceOptions.sort((a,b) => {
                if(!a?.id){
                    return 1
                }else{
                if (this.isLeaseEnded(a) === this.isLeaseEnded(b)) {
                    return 0;
                } else if (this.isLeaseEnded(a)) {
                    return 1;
                } else {
                    return -1;
                }}
                
            })
            this.countActiveLeases()
            if(this.setSelection && this.activeLeaseCount > 1){
                this.mainView=this.sortedSpaceOptions[1]
            }

            this.$emit('setDefaultSpaceValue',this.mainView);
        },
        countActiveLeases(){
            this.activeLeaseCount=0
            this.sortedSpaceOptions.forEach((lease)=>{
                if(!lease.end_date?.length){
                    this.activeLeaseCount += 1
                }
            });
        }
        
    },
};
</script>

<style lang="scss" scoped></style>