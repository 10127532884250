<template>
  <hb-modal
    v-model="dialog"
    size="large"
    :title="dialogTitle"
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:subheader>
      <span>{{dialogSubHeader}}</span>
    </template>
    <template v-slot:content>
      <v-container class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <v-col cols="3" class="field-label font-weight-medium px-4 text-capitalize" :class="selectorClass">
            {{label}}
          </v-col>
          <v-col cols="9" class="px-4" style="padding-top: 6px;">
            <v-row class="ma-0 pa-0">
              <v-col cols="9" class="pa-0 ma-0">
                <v-select
                  :class="{'custom-field-error' : errors.first(`${label}`)}"
                  :items="allowedPromotions"
                  item-text="name"
                  item-value="id"
                  return-object
                  hide-details
                  single-line
                  id="promotion_id"
                  :label="`Select a ${isLabelPromotion ? 'Promotion' : 'Discount'}`"
                  :data-vv-name="label"
                  :data-vv-as="label"
                  :error-message="errors.collect(`${label}`)"
                  class="pa-0 ma-0 text-capitalize"
                  v-validate="'required'"
                  v-model="selectedPromotion"
                  @change="generatePromotionInvoice"
                >
                </v-select>
              </v-col>
            </v-row>
              <div class="hb-text-light py-3" v-if="selectedPromotion">{{selectedPromotion.description}}</div>
              <hb-notification type="caution" title-off :notDismissable="true" class="mb-4" v-if="isPaymentRequired">
                A payment is required to add the selected promotion.
              </hb-notification>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-4" v-if="isPaymentRequired">
          <v-col cols="12">
            <payment-process
              :contactID="contactId" 
              :propertyID="lease.Unit.property_id" 
              paymentSource="PROMOTION"  
            >
            </payment-process>
          </v-col>
        </v-row>
      </v-container>
      <hb-modal size="medium" v-model="confirm_change" :title="confirmModalTitle" confirmation show-help-link>
        <template v-slot:content>
          <div class="px-6 py-4" v-if="isLabelPromotion">This update will be applied to the tenants next invoice.</div>
          <div class="px-6 py-4" v-else>You are about to change the applied discount. This change will affect upcoming invoices on this account.</div>
          <div class="px-6 pb-4" v-if="isLabelPromotion">Are you sure you want to add this promotion?</div>
          <div class="px-6 pb-4" v-else>Are you sure you want to change the discount?</div>
        </template>
        <template v-slot:actions>
          <hb-btn color="primary" @click="save"
            >{{dialogConfirmBtnText}}</hb-btn
          >
        </template>
      </hb-modal>
    </template>
    <template v-slot:actions>
      <span>
        <hb-btn color="primary" @click="confirmSave" :disabled="isDisabled || disablePayment" :loading="disablePayment">{{dialogConfirmBtnText}}</hb-btn>
      </span>
    </template>
  </hb-modal>
</template>
<script type="text/babel">
import api from '../../assets/api.js';
import PaymentProcess from '../includes/PaymentProcess/Index.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { EventBus } from '../../EventBus.js';
import { notificationMixin } from  '../../mixins/notificationMixin.js';

export default {
  name: "PromotionDialog",
  mixins: [ notificationMixin ],
  data() {
    return {
      confirm_change: false,
      allowedPromotions: [],
      selectedPromotion: null,
      invoicesToPay: {},
      disablePayment: false
    };
  },
  props: ["selected", "value", "label", "lease", "promotions", "contactId", "propertyId"],
  components: {
    PaymentProcess
  },
  async created() {
    if(this.selected.id) {
      this.selectedPromotion = this.selected.Promotion;
    }
    await this.fetchAllowedPromotions();
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors'
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isLabelPromotion() {
      return this.label == 'promotion' ? true : false;
    },
    dialogTitle() {
      return this.isLabelPromotion ? "Add a Promotion" : this.getDiscountText;
    },
    dialogSubHeader() {
      return this.isLabelPromotion ? "The selected promotion will adjust the balance of the account." : "The selected discount will be applied to the account.";
    },
    dialogConfirmBtnText() {
      return this.isLabelPromotion ? "Add Promotion" : this.getDiscountText;
    },
    getDiscountText() {
      return this.selected.id ? "Change Discount" : "Add Discount"
    },
    isDisabled() {
      return this.selectedPromotion ? (this.selectedPromotion.id == this.selected.promotion_id) ? true : false : true;
    },
    confirmModalTitle() {
      return this.isLabelPromotion ? 'Add Promotion' : 'Change Discount';
    },
    isPaymentRequired() {
      return this.selectedPromotion ? (this.selectedPromotion.months_to_pay >= 1 ? true : false) : false;
    },
    selectorClass() {
        return this.isPaymentRequired ? 'selector-width-payment' : 'selector-width';
    }
  },
  filters: {},
  methods: {
    ...mapMutations({
      createLeaseStructure: 'paymentsStore/createLeaseStructure',
      setLeaseBilledMonths: 'paymentsStore/setLeaseBilledMonths',
      setCheckErrors: 'paymentsStore/setCheckErrors',
      checkOnlyCredit: 'paymentsStore/checkOnlyCredit'
    }),
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
    }),
    async generatePromotionInvoice() {
      if(this.isPaymentRequired) {    
        let promotionsArray = [];
        let id = this.selectedPromotion.id
        promotionsArray.push(id.toString());

        const promotions = JSON.stringify(promotionsArray);
        let response = await api.get(
          this, `${api.LEASES}/${this.lease.id}/advance-pay?billed_months=${this.selectedPromotion.months_to_pay}&promotion_ids=${promotions}`
        );

        this.invoicesToPay = response.invoices;

        this.createLeaseStructure({
          openInvoices: response?.open_invoices || [],
          invoices: this.invoicesToPay,
          lease: this.lease,
          unit: this.lease.Unit
        });

        this.setLeaseBilledMonths({
          leaseIndex: 0,
          billedMonths: this.selectedPromotion.months_to_pay
        });

        EventBus.$emit('updatePaymentApplication');
      }
    },
    async enablePayment(response) {
      this.disablePayment = false;

      if(response && response.error) {
        this.showMessageNotification({ list: [response.error] });
      } else {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('payment_saved');
      }
    },
    async processPayment() {
      this.disablePayment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disablePayment = false;
        return;
      }

      if(paymentInfo && paymentInfo.payment) {
        let response = {};

        try {
          
          const responseData = await this.payInvoice(paymentInfo);

            response = {
              data: {
                msg: {
                  id: this.$options.name,
                  text: "Your Payment has been processed successfully",
                }
              }
            };

            EventBus.$emit('lease_edited');
            EventBus.$emit('refresh_lease_tenants');
            EventBus.$emit('promotionAdded');
            if(responseData.payment_id) {
              EventBus.$emit('showPaymentDone', responseData.payment_id);
            }
        } catch(err){
          response = {
            error: err
          };
          
        } finally {
          this.enablePayment(response);
        }                
      }              
    },
    async fetchAllowedPromotions() {
      if(this.isLabelPromotion) {
        let r = await api.get(this, api.LEASES + this.lease.id + '/promotions');
        this.allowedPromotions = r;
        this.allowedPromotions = this.setAllowedPromotions(this.allowedPromotions);
      } else {
        let url = api.getPropertyDiscountManagementUrl(this.lease.Unit.property_id);
        let r = await api.get(this, `${url}${this.contactId ? `?contact_id=${this.contactId}`: ''}`);
        this.allowedPromotions = this.setAllowedPromotions(r?.[0]?.discounts ?? []);

      }
    },
    async payInvoice(paymentInfo) {
      let data =  this.selectedPromotion;
      const res = await api.post(this, api.LEASES + this.lease.id + '/discount', 
        { id: data.id, label: this.label, payment_details: paymentInfo }
      );
      return res;
    },
    setAllowedPromotions(data) {
      const { present, future } = this.promotions;
      let allowedPromotionsData = [];
      if(future.length > 0) {
        data.map(obj => {
          let foundInPresent = present.filter((p, j) => (p.promotion_id == obj.id));
          let foundInFuture = future.filter((f, i) => (i > 0 && f.promotion_id == obj.id));
          if(!foundInPresent.length && !foundInFuture.length) allowedPromotionsData.push(obj);
        });
      }
      else {
        data.map(obj => {
          let foundInPresent = present.filter((p, j) => (j > 0 && p.promotion_id == obj.id));
          let foundInFuture = future.filter((f, i) => (f.promotion_id == obj.id));
          if(!foundInPresent.length && !foundInFuture.length) allowedPromotionsData.push(obj);
        });
      }
      return allowedPromotionsData;
    },
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async confirmSave() {
      if(this.isPaymentRequired) {
        this.checkPaymentsErrors();
        return;
      }

      if(this.selected.id || this.isLabelPromotion) {
        this.confirm_change = true;
      } else {
        await this.save();
      }
    },
    async save() {
      let data =  this.selectedPromotion;
      this.confirm_change = false;
      this.$emit('save', data);
    }
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  }
};

</script>

<style scoped>
.selector-width-payment {
  min-height: 12em  
}

.selector-width {
  min-height: 28em
}
</style>
