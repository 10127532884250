<template>
  <div>
    <hb-modal
      :title="'Auction: Space ' + auctionLease.Unit.number"
      v-model="dialog"
      @close="close"
      :footerCancelOption="!change_auction_info && (showSubHeader || isMoveOut)"
      show-help-link
    >
      <template v-slot:subheader v-if="showSubHeader">
        <span> {{getSubHeader()}} </span>
      </template>
      <template v-slot:content>
        <!-- <status
          @resetStatus="errorClear($options.name)"
          v-if="errorHas($options.name)"
          :message="errorGet($options.name)"
          status="error"
          class="pa-3"
        ></status>
        <status
          @resetStatus="successClear($options.name)"
          v-if="successHas($options.name)"
          :message="successGet($options.name)"
          status="success"
          class="pa-3"
        ></status> -->
        <v-card-text class="pa-0 ma-0">
          <v-container class="pa-0 ma-0">
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                Auction Type
              </v-col>
              <v-col class="ma-0 pa-4" cols="6">
                <v-select
                  v-if="!scheduled_auction.id || change_auction_info"
                  v-model="scheduled_auction.auction_type"
                  :items="auctionTypes"
                  item-value="value"
                  item-text="text"
                  name="auction_type"
                  data-vv-as="auction type"
                  label="Select an auction type"
                  :error="errors.collect('auction_type').length ? true : false"
                  v-validate="'required'"
                ></v-select>
  
                <v-row
                  class="pt-0 pl-4"
                  v-if="scheduled_auction.id && !change_auction_info"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="text-capitalize pa-0 ma-0">
                    {{ scheduled_auction.auction_type }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                Date and Time
              </v-col>
              <v-col class="ma-0 py-4 pt-0" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info}" cols="9">
                <v-row class="pa-0">
                  <v-col
                    cols="6"
                    v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                  >
                    <hb-date-picker
                      v-model="scheduled_auction.date"
                      v-validate="'required'"
                      id="date"
                      name="due_date"
                      label="Select Date"
                      :solo="false"
                      :min="minAuctionDate"
                      data-vv-as="date"
                      :error="errors.collect('due_date').length ? true : false"
                    ></hb-date-picker>
                  </v-col>
                  <v-col
                    cols="6"
                    v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                  >
                    <label class="v-label v-label--active theme--light font-11px">Select End Time</label>
                    <v-row no-gutters class="mt-1">
                      <v-col md="3" class="ma-0 pa-0">
                        <hb-text-field
                          id="auction_hour"
                          name="auction_hour"
                          placeholder="HH"
                          v-model="timeValue.hours"
                          data-vv-as="Hours"
                          type="number"
                          min="1"
                          max="12"
                          width="80%"
                          v-validate="'required|numeric|max_value:12|min_value:1'"
                          hide-details
                          :error="errors.collect('auction_hour').length > 0"
                          class="mt-0 pt-0 time-w-80"
                        ></hb-text-field>
                      </v-col>
                      <v-col md="3" class="ma-0 pa-0">
                        <hb-text-field
                          id="auction_minutes"
                          name="auction_minutes"
                          label="MM"
                          v-model="timeValue.minutes"
                          data-vv-as="Minutes"
                          type="number"
                          min="0"
                          max="59"
                          width="80%"
                          v-validate="'required|numeric|max_value:59|min_value:0'"
                          hide-details
                          :error="errors.collect('auction_minutes').length > 0"
                          class="mt-0 pt-0 time-w-80"
                        ></hb-text-field>
                      </v-col>
                      <v-col md="6" class="ma-0 pa-0">
                        <hb-select
                          :items="['AM','PM']"
                          id="auction_ampm"
                          name="auction_ampm"
                          v-model="timeValue.ampm"
                          :clearable="false"
                          label="AM/PM"
                          v-validate="'required'"
                          data-vv-name="auction_ampm"
                          data-vv-as="AM/PM"
                          hide-details
                          width="80%"
                          :error="errors.collect('auction_ampm').length > 0"
                          class="mt-0 pt-0 time-w-80"
                        ></hb-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="scheduled_auction.id && fieldEditing == 'date_time' && !change_auction_info"
                    class="ma-0 pt-0 pb-0 d-flex align-center"
                  >
                    <hb-btn
                      color="primary"
                      small="true"
                      @click="showEditAuctionConfirmation = true"
                      >Save</hb-btn
                    >
                    <hb-link class="ml-2" small="true" @click="clearEditField()"
                      >Cancel</hb-link
                    >
                  </v-col>
                </v-row>
  
                <v-row
                  class="pt-4 pl-4"
                  v-if="fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info"
                  @click="editField('date_time')"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.date | formatLocalShortDate }} @
                    {{ scheduled_auction.scheduled_date | formatTime12Hour }}
                  </v-col>
                  <v-col v-if="scheduled_auction.id" cols="1" class="pa-0 ma-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }">
                          <span v-on="on">
                            <hb-icon small :color="hover ? '#101318' : ''"
                              >mdi-pencil</hb-icon
                            >
                          </span>
                        </v-hover>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row
                  class="pt-4 pl-5" 
                  v-if="!change_auction_info && (isAuctionDay || isMoveOut)"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.date | formatLocalShortDate }} @
                    {{ scheduled_auction.scheduled_date | formatTime12Hour }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
              <v-col class="hb-table-label py-4 pl-4" cols="3"> Winning Bid 
                <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        Amount of the bid placed by the Winner. Auction Tax will be calculated on this amount.                      
                      </template>
                </hb-tooltip>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9"  v-if="!isMoveOut">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      label="Enter Amount"
                      class="pa-0 mt-0"
                      flat
                      id="amount"
                      name="winning_bid_amount"
                      data-vv-as="winning bid amount"
                      v-model="scheduled_auction.amount"
                      v-validate="schedule_new_auction ? '': 'required|decimal:2|min_value:0'"
                      :error="errors.collect('winning_bid_amount').length"
                      @blur="validateField('winning_bid_amount')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      class="pa-0 mt-0"
                      flat                 
                      v-model="scheduled_auction.auction_cleaning_deposit"
                      v-validate="'required|decimal:2|min_value:0'"
                      data-vv-as="cleaning deposit"
                      label="Cleaning Deposit"
                      id="cleaning_deposit"
                      name="cleaning_deposit"
                      :error="errors.collect('cleaning_deposit').length"
                      @blur="validateField('cleaning_deposit')"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="pa-0 mt-0"
                      flat                  
                      v-if="scheduled_auction.id"
                      v-validate="'required|min_value:0'"
                      data-vv-as="clean out period"
                      label="Clean Out Period"
                      id="cleaning_period"
                      name="cleaning_period"
                      :error="errors.collect('cleaning_period').length"
                      v-model="scheduled_auction.auction_cleaning_period"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9"  v-if="isMoveOut">
                <v-row
                  class="pt-0 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.amount | formatMoney }}
                    <hb-status color="#02AD0F">Paid</hb-status>
                  </v-col>
                </v-row>
                <v-row
                  class="pt-3 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    Cleaning Deposit: {{ auctionCleaningDeposit | formatMoney }}
                    <hb-btn small color="secondary" @click="$emit('refundAuction', scheduled_auction)" v-if="!scheduled_auction.refund_id && !auction_refund_id" :disabled="disableRefundButton"
                      >Refund</hb-btn>
                    <hb-status color="#919EAB" v-else>Refunded</hb-status>
                  </v-col>
                </v-row>
                <v-row
                  class="pt-3 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    Cleaning Up Period: {{ scheduled_auction.cleaning_period }} Days
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <div class="buyer-premium-label">
                  <span class="block">Buyer Premium</span>
                  <span class="ml-1">
                    <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        Premium paid by the bidder to the auctioneer<br>or online auction company.<br>This is for record purpose only.                      </template>
                    </hb-tooltip>
                  </span>
                </div>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9">
                <v-row>
                  <v-col cols="6">
                    <hb-select
                      v-model="scheduled_auction.BuyerPremium.type"
                      v-validate="'required'"
                      :items="feeTypes"
                      item-value="value"
                      item-text="text"
                      name="buyer_premium_type" 
                      data-vv-as="buyer premium type"
                      label="Select type"
                      class="pa-0 mt-0"
                      :error="errors.collect('buyer_premium_type').length"
                      @blur="validateField('buyer_premium_type')"
                    >
                    </hb-select>
                  </v-col>
                  <v-col cols="6">
                    <hb-text-field 
                      v-model="scheduled_auction.BuyerPremium.value"
                      v-validate="scheduled_auction.BuyerPremium.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                      label="Value"
                      name="buyer_premium_value"
                      data-vv-as="buyer premium value"
                      flat
                      class="pa-0 mt-0"
                      :error="errors.collect('buyer_premium_value').length"
                      @blur="validateField('buyer_premium_value')"
                    >
                    </hb-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isBuyerPremiumPresent">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <div class="buyer-premium-label">
                  <span class="block">Buyer Premium</span>
                  <span class="ml-1">
                    <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        Premium paid by the bidder to the auctioneer<br>or online auction company.<br> This is for record purpose only.
                      </template>
                    </hb-tooltip>
                  </span>
                </div>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Type: {{ scheduled_auction.BuyerPremium.type }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Value: {{ scheduled_auction.BuyerPremium.value }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Product: {{ getAuctionProductNameById(scheduled_auction.BuyerPremium.product_id) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <div class="buyer-premium-label">
                  <span class="block">Seller Premium</span>
                  <span class="ml-1">
                    <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                      </template>
                    </hb-tooltip>
                  </span>
                </div>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9">
                <v-row>
                  <v-col cols="6">
                    <hb-select
                      v-model="SellerPremium.type"
                      v-validate="'required'"
                      :items="feeTypes"
                      item-value="value"
                      item-text="text"
                      name="seller_premium_type" 
                      data-vv-as="seller premium type"
                      label="Select type"
                      class="pa-0 mt-0"
                      :error="!!errors.collect('seller_premium_type').length"
                      @blur="validateField('seller_premium_type')"
                    >
                    </hb-select>
                  </v-col>
                  <v-col cols="6">
                    <hb-text-field 
                      v-model="SellerPremium.value"
                      v-validate="SellerPremium?.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                      label="Value"
                      name="seller_premium_value"
                      data-vv-as="seller premium value"
                      flat
                      class="pa-0 mt-0"
                      :error="!!errors.collect('seller_premium_value').length"
                      @blur="validateField('seller_premium_value')"
                    >
                    </hb-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSellerPremiumPresent">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <div class="buyer-premium-label">
                  <span class="block">Seller Premium</span>
                  <span class="ml-1">
                    <hb-tooltip>
                      <template v-slot:item>
                        <hb-icon small mdi-code="mdi-information"></hb-icon>
                      </template>
                      <template v-slot:body>
                        The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                      </template>
                    </hb-tooltip>
                  </span>
                </div>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Type: {{ SellerPremium.type }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Value: {{ SellerPremium.value }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0">
                    Product: {{ getAuctionProductNameById(SellerPremium.product_id) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
              <v-col class="hb-table-label py-4 pl-4" cols="3">Auction Winner</v-col>
              <v-col class="ma-0 pl-4" cols="9" v-if="!isMoveOut">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      :error-messages="errors.collect('first_name')"
                      :hide-details="!errors.collect('first_name').length"
                      label="First Name"
                      class="pa-0 mt-0"
                      flat
                      id="first_name"
                      name="first_name"
                      data-vv-as="First Name"
                      v-model="scheduled_auction.Contact.first"
                      :v-validate="schedule_new_auction ? '': 'required'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      class="pa-0 mt-0"
                      flat                  
                      v-model="scheduled_auction.Contact.last"
                      :hide-details="!errors.collect('last_name').length"
                      :v-validate="schedule_new_auction ? '': 'required'"
                      data-vv-as="Last Name"
                      label="Last Name"
                      id="last_name"
                      name="last_name"
                      :error-messages="errors.collect('last_name')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="12" class="pt-1 pb-1">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      :error-messages="errors.collect('email')"
                      :hide-details="!errors.collect('email').length"
                      label="Email"
                      class="pa-0 mt-0"
                      flat
                      id="email"
                      name="email"
                      data-vv-as="Email"
                      v-model="scheduled_auction.Contact.email"
                      :v-validate="schedule_new_auction ? '': 'required'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="7" class="pt-1 pb-1">
                    <VuePhoneNumberInput class="pa-0 pb-1 custom-styling"
                      v-model="scheduled_auction.Contact.Phones[0].phone"
                      :default-country-code="scheduled_auction.Contact.country_code"
                      default-country-code="US"
                      label="Mobile Number"
                      show-code-on-list
                      no-example
                      id="mobile_no"
                      name="mobile_no"
                      @update="updatePhoneNumber"
                      color="#949494"
                      valid-color="#949494"
                      dense
                      flat
                      :translations="mobileNoWording"
                    />                  
                  </v-col>
                </v-row>
  
                <v-row>
                  <v-col cols="6">
                    <v-checkbox
                      class="my-0"
                      flat
                      hide-details
                      id="tax_exempt"
                      name="tax_exempt"
                      single-line
                      solo
                      v-model="scheduled_auction.tax_exempt"
                      @change="generateAuctionInvoices"
                    >
                      <span slot="label">Tax Exempt (Reseller License)</span>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="scheduled_auction.tax_exempt">
                  <v-col cols="6">
                    <v-text-field
                      v-if="scheduled_auction.id"
                      :error-messages="errors.collect('license_number')"
                      :hide-details="!errors.collect('license_number').length"
                      label="Reseller License Number"
                      class="pa-0 mt-0"
                      flat
                      id="license_number"
                      name="license_number"
                      data-vv-as="license number"
                      v-model="scheduled_auction.license_number"
                      :v-validate="schedule_new_auction ? '': 'required'"
                      v-mask="'##-######'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                <v-row
                  class="pt-0 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.Contact.first }} {{ scheduled_auction.Contact.last }}
                  </v-col>
                </v-row>
                <v-row
                  class="pt-1 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.Contact.email }}
                  </v-col>
                </v-row>
                <v-row
                  class="pt-1 pl-4"
                  v-if="scheduled_auction.id"
                >
                  <v-col v-if="scheduled_auction.Contact.Phones.length && scheduled_auction.Contact.Phones[0].phone" cols="11" class="pa-0 ma-0">
                    {{ 1 + scheduled_auction.Contact.Phones[0].phone | formatPhone }}
                  </v-col>
                </v-row>
                <v-row
                  class="pt-1 pl-4"
                  v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    Tax Exempt (Reseller License)
                  </v-col>
                </v-row>
                <v-row
                  class="pt-1 pl-4"
                  v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.license_number }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
  
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-4" cols="3"> <span v-if="change_auction_info">Reason for Rescheduling</span><span v-else>Notes</span></v-col>
              <v-col class="ma-0 pa-4" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'notes' && !isAuctionDay && !isMoveOut}" cols="9">
                <v-row class="pa-0">
                  <v-col
                    cols="12"
                    v-if="!scheduled_auction.id || fieldEditing == 'notes' || isAuctionDay || change_auction_info"
                  >
                    <v-textarea
                      class="mt-0 pt-0"
                      :hide-details="!errors.collect('notes').length"
                      v-validate="'max:1000'"
                      data-vv-as="notes"
                      label="Enter a Note"
                      id="notes"
                      name="notes"
                      :error-messages="errors.collect('notes')"
                      auto-grow
                      autofocus
                      rows="1"
                      v-model="scheduled_auction.notes"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="scheduled_auction.id && fieldEditing == 'notes'"
                    class="ma-0 pt-0 pb-0 d-flex align-center"
                  >
                    <hb-btn
                      color="primary"
                      small="true"
                      @click="scheduleAuction"
                      :disabled="isLoading($options.name)"
                      >Save</hb-btn
                    >
                    <hb-link class="ml-2" small="true" @click="clearEditField()"
                      >Cancel</hb-link
                    >
                  </v-col>
                </v-row>
                <v-row
                  class="pl-4"
                  v-if="fieldEditing != 'notes' && !isAuctionDay && !isMoveOut"
                  @click="editField('notes')"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.notes }}
                  </v-col>
                  <v-col v-if="scheduled_auction.id" cols="1" class="pa-0 ma-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }">
                          <span v-on="on">
                            <hb-icon small :color="hover ? '#101318' : ''"
                              >mdi-pencil</hb-icon
                            >
                          </span>
                        </v-hover>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row
                  class="pl-4"
                  v-if="isMoveOut"
                >
                  <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                    {{ scheduled_auction.notes }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <span>Summary</span>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9">
                <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body">
                    Winning Bid: ${{ summary.winning_bid }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body">
                    Seller's Premium: ${{ summary.seller_premium_cost }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Remaining Bid: ${{ summary.remaining_bid }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Cleaning Deposit: ${{ summary.cleaning_deposit }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Auction Tax: ${{ summary.total_tax }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Invoice Total: ${{ summary.invoice_total }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSummary">
              <v-col class="hb-table-label py-4 pl-4" cols="3">
                <span>Summary</span>
              </v-col>
              <v-col class="ma-0 pl-4" cols="9">
                <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body">
                    Winning Bid: ${{ summary.winning_bid }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body">
                    Seller's Premium: ${{ summary.seller_premium_cost }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Remaining Bid: ${{ summary.remaining_bid }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Cleaning Deposit: ${{ summary.cleaning_deposit }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Auction Tax: ${{ summary.total_tax }}
                  </v-col>
                </v-row>
                <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                  <v-col class="pa-0 ma-0 hb-font-body-medium">
                    Invoice Total: ${{ summary.invoice_total }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
  
            <div class="pa-6" v-if="!change_auction_info && isAuctionDay" style="background: #f9fafb;">

              <hb-notification
                v-model="showWarning"
                v-if="showWarning"
                type="caution"
                notDismissable
              >
                Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the ‘Auction Payment' is processed.
              </hb-notification>
  
              <payment-process 
                  :propertyID="property_id"  
                  :invoice="auction_invoices"
                  paymentSource="AUCTION">
              </payment-process>
  
            </div>
  
            <hb-modal v-model="showEditAuctionConfirmation" show-help-link v-if="showEditAuctionConfirmation" size="medium" confirmation title="Confirm Auction Update" @close="closeEditConfirmation">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to reschedule the auction date for Space {{ auctionLease.Unit.number }}.</span>
                    <br /><br />
  
                    Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.
  
                    <br />
                    <span class="fw-500">Are you sure you want to reschedule?</span>
                  </div>
              </template>
              <template v-slot:actions>
                  <hb-btn @click="scheduleAuction(false, true)" color="primary" :disabled="isLoading($options.name)" >Confirm</hb-btn>
              </template>
            </hb-modal>
  
            <hb-modal v-model="showRemoveAuctionConfirmation" show-help-link v-if="showRemoveAuctionConfirmation" size="medium" confirmation title="Confirm Auction Removal" @close="closeRemoveConfirmation">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to remove the auction date for Space {{ auctionLease.Unit.number }}.</span>
                    <br /><br />
  
                    Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.
  
                    <br />
                    <span class="fw-500">Are you sure you want to remove?</span>
                  </div>
              </template>
              <template v-slot:actions>
                  <hb-btn @click="removeAuctionSchedule" color="destructive">Confirm</hb-btn>
              </template>
            </hb-modal>
            <!-- Change Auction Confirmation Modal -->
            <hb-modal v-model="showChangeAuctionConfirmation" show-help-link v-if="showChangeAuctionConfirmation" size="medium" confirmation title="Confirm Auction Change" @close="showChangeAuctionConfirmation = false">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to change the auction date for Space {{ auctionLease.Unit.number }}.</span>
                    <br /><br />
                    Changing an auction date may not comply with your states legal requirements, please verify that this aligns with your lien process.
                    <br /><br />
                    <span>If you schedule a new date, any information you've previously saved will also be deleted.</span>
                  </div>
              </template>
              <template v-slot:right-actions>
                  <hb-btn @click="scheduleNewAuction" color="secondary">Schedule New Auction</hb-btn>
                  <hb-btn @click="changeAuctionInfo" color="primary">Change Info</hb-btn>
              </template>
            </hb-modal>
            <!-- Change Auction Confirmation Modal -->
            <!-- Schedule New Auction Modal -->
            <hb-modal v-model="schedule_new_auction" show-help-link v-if="schedule_new_auction" :title="'Auction: Space ' + auctionLease.Unit.number" @close="schedule_new_auction = false">
              <template v-slot:subheader>
                <span>Choose whether this is going to be an online or live auction, and then schedule the date and time for the auction.</span>
              </template>
              <template v-slot:content>
                  <status
                    @resetStatus="errorClear($options.name)"
                    v-if="errorHas($options.name)"
                    :message="errorGet($options.name)"
                    status="error"
                    class="pa-3"
                  ></status>
                  <status
                    @resetStatus="successClear($options.name)"
                    v-if="successHas($options.name)"
                    :message="successGet($options.name)"
                    status="success"
                    class="pa-3"
                  ></status>
                  <v-card-text class="pa-0 ma-0">
                    <v-container class="pa-0 ma-0">
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Auction Type
                        </v-col>
                        <v-col class="ma-0 pa-4" cols="6">
                          <v-select
                            v-model="scheduled_auction.auction_type"
                            :items="auctionTypes"
                            item-value="value"
                            item-text="text"
                            name="auction_type"
                            data-vv-as="auction type"
                            label="Select an auction type"
                            :hide-details="!errors.collect('auction_type').length"
                            :error-messages="errors.collect('auction_type')"
                            v-validate="'required'"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Date and Time
                        </v-col>
                        <v-col class="ma-0 py-4 pt-0" cols="9">
                          <v-row class="pa-0">
                            <v-col
                              cols="6"
                            >
                              <hb-date-picker
                                v-model="scheduled_auction.date"
                                id="date"
                                name="due_date"
                                label="Select Date"
                                :solo="false"
                                :min="minAuctionDate"
                              ></hb-date-picker>
                            </v-col>
                            <v-col
                              cols="6"
                            >
                              <label class="v-label v-label--active theme--light font-11px">Select End Time</label>
                              <v-row no-gutters class="mt-1">
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_hour"
                                    name="auction_hour"
                                    placeholder="HH"
                                    v-model="timeValue.hours"
                                    data-vv-as="Hours"
                                    type="number"
                                    min="1"
                                    max="12"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:12|min_value:1'"
                                    hide-details
                                    :error="errors.collect('auction_hour').length > 0"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_minutes"
                                    name="auction_minutes"
                                    label="MM"
                                    v-model="timeValue.minutes"
                                    data-vv-as="Minutes"
                                    type="number"
                                    min="0"
                                    max="59"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:59|min_value:0'"
                                    hide-details
                                    :error="errors.collect('auction_minutes').length > 0"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="6" class="ma-0 pa-0">
                                  <hb-select
                                    :items="['AM','PM']"
                                    id="auction_ampm"
                                    name="auction_ampm"
                                    v-model="timeValue.ampm"
                                    :clearable="false"
                                    label="AM/PM"
                                    v-validate="'required'"
                                    data-vv-name="auction_ampm"
                                    data-vv-as="AM/PM"
                                    hide-details
                                    width="80%"
                                    :error="errors.collect('auction_ampm').length > 0"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-select>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">Notes</v-col>
                        <v-col class="ma-0 pa-4" cols="9">
                          <v-row class="pa-0">
                            <v-col
                              cols="12"
                            >
                              <v-textarea
                                class="mt-0 pt-0"
                                :hide-details="!errors.collect('notes').length"
                                v-validate="'max:1000'"
                                data-vv-as="notes"
                                label="Enter a Note"
                                id="notes"
                                name="notes"
                                :error-messages="errors.collect('notes')"
                                auto-grow
                                autofocus
                                rows="1"
                                v-model="scheduled_auction.notes"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
              </template>
              <template v-slot:actions>
                  <hb-btn @click="scheduleAuction(false, true)" color="primary" class="ml-2" :disabled="isLoading($options.name)">Schedule</hb-btn>
              </template>
            </hb-modal>
            <!-- Schedule New Auction Modal -->
          </v-container>
        </v-card-text>
      </template>
      <template v-slot:right-actions>
        <v-progress-circular
          v-if="isLoading($options.name) || disable_payment"
          indeterminate
          color="primary"
          class="mr-0"
        ></v-progress-circular>
        <span v-else>
          <hb-link small="true" @click="backToAddInfo" v-if="change_auction_info && !schedule_new_auction">Back</hb-link>
          <hb-btn
            v-if="(scheduled_auction.id && !isAuctionDay && !isMoveOut) || change_auction_info"
            color="secondary"
            @click="showRemoveAuctionConfirmation = true"
          >
            Remove from Auction List
          </hb-btn>
          <hb-btn
            v-if="!scheduled_auction.id || change_auction_info"
            color="primary"
            @click="scheduleAuction(false, true)"
            :disabled="!enableNewSchedule || isLoading($options.name)"
            >Schedule</hb-btn
          >
          <hb-btn v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut" color="primary" @click="close"
            >Close</hb-btn
          >
          <hb-btn v-if="scheduled_auction.id && isAuctionDay && !change_auction_info" color="secondary" @click="showChangeAuctionConfirmation = true" :disabled="disable_payment"
            >Change Auction</hb-btn
          >
          <hb-btn v-if="scheduled_auction.id && isAuctionDay  && !change_auction_info" color="secondary" @click="scheduleAuction" :disabled="enableSaveBtn || disable_payment || isLoading($options.name)"
            >Save</hb-btn
          >
          <hb-btn v-if="scheduled_auction.id && isAuctionDay && !change_auction_info" color="primary" @click="checkPaymentsErrors" :disabled="enableSaveBtn || disable_payment" :loading="disable_payment"
            >Process Payment</hb-btn
          >
          <hb-btn v-if="scheduled_auction.id && isMoveOut" color="primary" @click="auctionMoveOut" :disabled="auctionLease.auction_status != 'move_out'"
            >Move-Out</hb-btn
          >
        </span>
      </template>
    </hb-modal>
    <hb-modal
      size="medium"
      title="Confirm Payment"
      v-model="confirm_process_payment"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the 'Auction Payment' is processed.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="confirmProcessPayment" :disabled="disable_payment">Would you like to proceed?</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>
<script type="text/babel">
import Status from "../includes/Messages.vue";
import { EventBus } from "../../EventBus.js";
import api from "../../assets/api.js";
import Loader from "../assets/CircleSpinner.vue";
import HbDatePicker from "../assets/HummingbirdDatepicker";
import HbTimePicker from "../assets/HummingbirdTimepicker";
import VuePhoneNumberInput from 'vue-phone-number-input';
import moment from "moment";
import PaymentProcess from '../includes/PaymentProcess/Index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import axios from 'axios';
import https from 'https';

export default {
  name: "AuctionDialog",
  mixins: [ notificationMixin ],
  data() {
    return {
      message: {
        type: "success",
        message: "Refund Processed.",
      },
      auctionTypes: [
        { text: "Online Auction", value: "online" },
        { text: "Live Auction", value: "holler" },
      ],
      feeTypes: [
        { text: "%", value: "percent" },
        { text: "Fixed Rate", value: "fixed" },
      ],
      auctionProducts: [],
      scheduled_auction: {
        Contact: {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        },
        id: "",
        auction_type: "",
        scheduled_date: "",
        date: "",
        time: "",
        notes: "",
        cleaning_deposit: null,
        cleaning_period: null,
        amount: null,
        tax_exempt: false,
        license_number: '',
        country_code: '',
        mobile_no: '',
        refund_id: '',
        BuyerPremium: {
          type: "",
          value: "",
          is_inclusive: false,
          amount: 0
        },
      },
      SellerPremium: null,
      summary: {
        remaining_bid: 0,
        winning_bid: 0,
        buyer_premium_cost: 0,
        seller_premium_cost: 0,
        total_tax: 0,
        cleaning_deposit: 0,
        invoice_total: 0,
      },
      mobileNoWording: {
        countrySelectorLabel: '',
        countrySelectorError: 'Select country code',
        phoneNumberLabel: 'Mobile Number'
      },
      fieldEditing: "",
      showRemoveAuctionConfirmation: false,
      showEditAuctionConfirmation: false,
      showChangeAuctionConfirmation: false,
      change_auction_info: false,
      schedule_new_auction: false,
      auction_invoices: [],
      disable_payment: false,
      process_payment: false,
      confirm_process_payment: false,
      showWarning: true,
      timeValue: {
        hours: '',
        minutes: '',
        ampm: ''
      }
    };
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    HbTimePicker,
    VuePhoneNumberInput,
    PaymentProcess
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors',
      hasPermission: "authenticationStore/rolePermission"
    }),
    minAuctionDate() {
      return (this.change_auction_info) ? null : moment().format('YYYY-MM-DD');
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isAuctionDay() {
      return (this.auctionLease.auction_status == 'auction_day' || this.auctionLease.auction_status == 'auction_payment') ? true : false;
    },
    isMoveOut() {
      return (this.auctionLease.auction_status == 'move_out' || this.auctionLease.auction_status == 'complete') ? true : false;
    },
    enableSaveBtn() {
      return (
        !this.scheduled_auction.amount ||
        !this.scheduled_auction.Contact.first ||
        !this.scheduled_auction.Contact.last ||
        !this.scheduled_auction.Contact.email ||
        !this.scheduled_auction.BuyerPremium.type ||
        (isNaN(parseFloat(this.scheduled_auction.BuyerPremium.value)) || this.scheduled_auction.BuyerPremium.value < 0) ||
        !this.SellerPremium.type ||
        (isNaN(parseFloat(this.SellerPremium.value)) || this.SellerPremium.value < 0)
      );
    },
    showSubHeader() {
      return (this.auctionLease.auction_status == 'schedule' || this.isAuctionDay) ? true : false;
    },
    auction_bidding_amount() {
      return this.auction_invoices && this.auction_invoices.balance;
    },
    isBuyerPremiumPresent() {
      return this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value && this.scheduled_auction.BuyerPremium.product_id
    },
    isSellerPremiumPresent() {
      return this.SellerPremium.type && this.SellerPremium.value && this.SellerPremium.product_id
    },
    isSummary() {
      return this.summary.remaining_bid !== 0 || this.summary.buyer_premium_cost !== 0 || this.summary.seller_premium_cost !== 0 || this.summary.cleaning_deposit !== 0 || this.summary.total_tax !== 0 || this.summary.invoice_total !== 0 || this.summary.winning_bid !== 0
    },
    enableNewSchedule() {
      return !!this.scheduled_auction.auction_type;
    },
    auctionCleaningDeposit() {
      return (this.auctionLease.LeaseAuction && this.auctionLease.LeaseAuction.cleaning_deposit && !this.auctionLease.LeaseAuction.refund_id) ? this.auctionLease.LeaseAuction.cleaning_deposit : 0;
    },
    disableRefundButton(){
      if (this.auctionLease.LeaseAuction.refund_id || this.auctionCleaningDeposit <= 0){
        return true;
      }
      else if (this.auctionLease.LeaseAuction.has_clean_out_period_passed){
        return !this.hasPermission('refund_after_clean_out_period');
      }
      return false;
    }
  },
  async created() {
    this.getAuctionProducts();
    const { LeaseAuction } = this.auctionLease;
    let momentData = moment();
    this.scheduled_auction.date = momentData.format("YYYY-MM-DD");
    let hoursOriginal = momentData.hours();
    let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = momentData.minutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    this.timeValue = {
      hours,
      minutes,
      ampm: hoursOriginal < 12 ? 'AM' : 'PM'
    }
    if (LeaseAuction && Object.keys(LeaseAuction).length!== 0) {
      this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
      this.SellerPremium = LeaseAuction.SellerPremium? JSON.parse(JSON.stringify(LeaseAuction.SellerPremium)): null;
      if(!this.scheduled_auction.contact_id){
        this.scheduled_auction.Contact = {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        };
      } else {
        if(!this.scheduled_auction.Contact.Phones.length){
          this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
        } else {
          this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
        }
      }
      let localScheduleDateTime = moment(
        this.scheduled_auction.scheduled_date
      ).format("YYYY-MM-DD HH:mm:ss");

      this.scheduled_auction.scheduled_date = localScheduleDateTime;
      this.scheduled_auction.date = moment(localScheduleDateTime).format(
        "YYYY-MM-DD"
      );
      let mommentTime = moment(localScheduleDateTime);
      let hoursOriginal = mommentTime.hours();
      let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
      hours = hours === 0 ? 12 : hours < 10 ? "0" + hours : hours;
      let minutes = mommentTime.minutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeValue = {
        hours,
        minutes,
        ampm: hoursOriginal < 12 ? 'AM' : 'PM'
      }
      if (!this.scheduled_auction.BuyerPremium) {
        this.scheduled_auction.BuyerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      if (!this.SellerPremium) {
        this.SellerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      
      this.scheduled_auction.auction_cleaning_deposit = this.scheduled_auction.cleaning_deposit === null ? this.scheduled_auction.default_cleaning_deposit : this.scheduled_auction.cleaning_deposit;
      this.scheduled_auction.auction_cleaning_period = this.scheduled_auction.cleaning_period === null ? this.scheduled_auction.default_cleaning_period : this.scheduled_auction.cleaning_period;
    }

    if(this.isAuctionDay) {
      this.generateAuctionInvoices();
    } else if(LeaseAuction.BidInvoice && Object.keys(LeaseAuction.BidInvoice).length !== 0){
      this.generateAuctionInvoices();
    }

    EventBus.$on('paymentReader', this.enablePayment);
  },
  mounted() {
  },
  destroyed(){
    EventBus.$off('paymentReader', this.enablePayment);
    this.closeMessageNotification();
  },
  filters: {
    formatDays(value) {
      return `${value} Days`;
    }
  },
  props: [
    "auctionLease",
    "contact_id",
    "property_id",
    "value",
    "payments",
    "unallocated",
    "auction_refund_id"
  ],
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
      getTasks: 'taskCenterStore/getTasks',
    }),
    ...mapMutations({
      setCheckErrors: 'paymentsStore/setCheckErrors',
      checkOnlyCredit:'paymentsStore/checkOnlyCredit'
    }),
    getAuctionProducts() {
      api.get(this, api.PRODUCTS + 'list?type=auction&category_type=fee').then(response => {
        const products = response.products.map(p => {
          return {
            value: p.id,
            text: p.name
          }
        });
        this.auctionProducts = products;
      }).catch((err) => {
        this.products = []
      });
    },
    getAuctionProductNameById(id) {
      const product = this.auctionProducts.find(item => item.value === id);
      return product ? product.text : "";
    },
    async enablePayment(response) {
      this.disable_payment = false;
      if(response && response.error) {
        this.showMessageNotification({ list: [response.error] })
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('contact_updated',  this.contact_id);
        EventBus.$emit('reservation_made');
        EventBus.$emit('payment_saved');
        if(this.process_payment && response.data.payment && response.data.payment.id) {
          this.$emit("processPayment", response.data.payment.id);
        }
        this.getTasks({ reset: true, role_ids: this.rolesIDs });
        this.$emit("close");
      }
    },
    backToAddInfo() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
    },
    scheduleNewAuction() {
      this.showChangeAuctionConfirmation = false;
      this.schedule_new_auction = true;
    },
    changeAuctionInfo() {
      this.showChangeAuctionConfirmation = false;
      this.dialog = true;
      this.change_auction_info = true;
    },
    auctionMoveOut() {
      this.$emit("close");
      this.$emit('moveOut', this.auctionLease);
    },
    /**
     * Winning bid amount and cleaning deposit will come from settings so their value will
     * be present. If not then we have either remove it or entered an invalid value.
     * That is why they can be put dependent on each other and other fields while validating.
     * @param {The field to be validated} field 
     */
    async validateField(field) {
      let status = true;
      switch (field) {
        case 'winning_bid_amount':
          status = await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          break;
        case 'cleaning_deposit':
          status = await this.$validator.validate('cleaning_deposit');
          status = status && await this.$validator.validate('winning_bid_amount');
          break;
        case 'buyer_premium_type':
          status = await this.$validator.validate('buyer_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.value) {
            status = await this.$validator.validate('buyer_premium_value');
          } else {
            status = false;
          }
          break;
        case 'buyer_premium_value':
          status = await this.$validator.validate('buyer_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.type) {
            status = await this.$validator.validate('buyer_premium_type');
          } else {
            status = false;
          }
          break;
          case 'seller_premium_type':
          status = await this.$validator.validate('seller_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.value) {
            status = await this.$validator.validate('seller_premium_value');
          } else {
            status = false;
          }
          break;
        case 'seller_premium_value':
          status = await this.$validator.validate('seller_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.type) {
            status = await this.$validator.validate('seller_premium_type');
          } else {
            status = false;
          }
          break;
        default:
          break;
      }
      if (status) {
        this.generateAuctionInvoices();
      }
    },
    async generateAuctionInvoices() {
      try {        
        let auctionInvoiceData = {
          winning_bid_amount: this.scheduled_auction.amount,
          lease_auction_id: this.scheduled_auction.id,
          cleaning_deposit: this.scheduled_auction.auction_cleaning_deposit,
          tax_exempt: this.scheduled_auction.tax_exempt,
        }
        if (this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value) {
          auctionInvoiceData.BuyerPremium = {
            type: this.scheduled_auction.BuyerPremium.type,
            value: this.scheduled_auction.BuyerPremium.value,
            is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.scheduled_auction.BuyerPremium.id) {
            auctionInvoiceData.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
          }
        }
        if (this.SellerPremium.type && this.SellerPremium.value) {
          auctionInvoiceData.SellerPremium = {
            type: this.SellerPremium.type,
            value: this.SellerPremium.value,
            is_inclusive: this.SellerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.SellerPremium.id) {
            auctionInvoiceData.SellerPremium.id = this.SellerPremium.id
          }
        }
        let response = await api.post(this, `${api.AUCTIONS}compute-auction-data`, auctionInvoiceData);
        this.auction_invoices = response.lease_auction.invoices;
        this.SellerPremium = response.lease_auction.SellerPremium || this.SellerPremium;
        this.auction_invoices.balance = response.lease_auction.invoice_total;
        this.calculateSummary()
      } catch (error) {
        this.showMessageNotification({ description: error.toString() });
      }
    },
    resetSummary() {
      this.summary.winning_bid = 0;
      this.summary.remaining_bid = 0;
      this.summary.buyer_premium_cost = 0;
      this.summary.seller_premium_cost = 0;
      this.summary.cleaning_deposit = 0;
      this.summary.total_tax = 0;
      this.summary.invoice_total = 0;
    },
    calculateSummary() {
      this.resetSummary();
      this.auction_invoices.forEach(invoice =>{
        invoice.InvoiceLines.forEach(element => {
          if (element.Product?.slug === 'remaining_bid'){
            this.summary.remaining_bid += element.cost
          }else if (element.Product?.slug === 'cleaning_deposit'){
            this.summary.cleaning_deposit += element.cost
          } 
        });
        this.summary.total_tax += invoice.total_tax;
      });
      this.summary.invoice_total = this.auction_invoices.balance;
      this.summary.seller_premium_cost = this.SellerPremium.amount;
      this.summary.winning_bid = this.scheduled_auction.amount || 0;
    },
    updatePhoneNumber(data){
      this.scheduled_auction.Contact.phone = data.formattedNumber;
      this.scheduled_auction.Contact.country_code = data.countryCode ;
    },
    closeRemoveConfirmation() {
      this.showRemoveAuctionConfirmation = false;
    },
    closeEditConfirmation() {
      this.showEditAuctionConfirmation = false;
    },
    editField(fieldName) {
      this.fieldEditing = fieldName;
    },
    clearEditField() {
      this.fieldEditing = "";
    },
    getSubHeader() {
      switch(this.auctionLease.auction_status) {
        case 'schedule':
          return 'Choose whether this is going to be an online or live auction, and then schedule the date and time for the auction.';
          break;
        case 'auction_day':
        case 'auction_payment':
          return 'Please enter the results from the auction.';
          break;
        default:
          break;
      }
    },
    async removeAuctionSchedule() {
      try {
        const response = await api.delete(
          this,
          api.LEASE_AUCTION + this.scheduled_auction.id
        ).then(r => {
          this.showMessageNotification({ type: 'success', description: 'Successfully Removed from Auction List' });
          EventBus.$emit('contact_updated',  this.contact_id);
          EventBus.$emit('reservation_made');
          EventBus.$emit('reload_tenant_profile_leases');

        });

        // this.$emit("notification", {
        //   type: "success",
        //   message: "Auction deleted successfully.",
        // });
        this.$emit("close");
      } catch (err) {
        this.$emit("notification", {
          type: "warning",
          message: err,
        });
      }
    },
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async fetchPropertyConnections(device_id) {
       let r = await api.get(this.$app, api.PROPERTIES + this.property_id + "/connections/address?device_id=" + device_id);
       this.IPaddress = r.IPaddress;
       this.lan = r.lan;
    },
    async scheduleAuction(processPayment = false, validateAuctionDatetime = false) {
      this.process_payment = processPayment;
      this.closeEditConfirmation();
      let auctionTime = this.getAuctionTime();
      let scheduleDateTime = moment(
        `${this.scheduled_auction.date} ${auctionTime}:00`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm");
      if(validateAuctionDatetime) {
        let status = await this.$validator.validateAll();
        if(!status) return;
        // let currentTime = moment().format("YYYY-MM-DD HH:mm");
        if(!moment(scheduleDateTime).isValid()) {
        // if(!moment(scheduleDateTime).isValid() || !moment(scheduleDateTime).isSameOrAfter(currentTime)) {
          this.showMessageNotification({ description: 'Invalid datetime provided' });
          return;
        }
      }
      let auction_data = {
        id: this.schedule_new_auction ? null : this.scheduled_auction.id,
        lease_id: this.auctionLease.id,
        unit_id: this.auctionLease.Unit.id,
        contact_id: this.contact_id,
        auction_type: this.scheduled_auction.auction_type,
        amount: this.schedule_new_auction ? null : this.scheduled_auction.amount,
        cleaning_deposit: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_deposit,
        cleaning_period: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_period,
        license_number: this.schedule_new_auction ? null : this.scheduled_auction.license_number,
        tax_exempt: this.schedule_new_auction ? false : this.scheduled_auction.tax_exempt,
        notes: this.scheduled_auction.notes,
        scheduled_date: scheduleDateTime,
        lease_auction_id: this.schedule_new_auction ? this.scheduled_auction.id : null,
        deleted: this.schedule_new_auction ? true : false,
      };
      if (!this.schedule_new_auction && this.scheduled_auction.BuyerPremium && this.scheduled_auction.BuyerPremium.value) {
        auction_data.BuyerPremium = {
          type: this.scheduled_auction.BuyerPremium.type,
          value: this.scheduled_auction.BuyerPremium.value,
          is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
        }
        if (this.scheduled_auction.BuyerPremium.id) {
          auction_data.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
        }
      }
      if (!this.schedule_new_auction && this.SellerPremium && this.SellerPremium.value) {
        auction_data.SellerPremium = {
          type: this.SellerPremium.type,
          value: this.SellerPremium.value,
          is_inclusive: this.SellerPremium.is_inclusive,
        }
        if (this.SellerPremium.id) {
          auction_data.SellerPremium.id = this.SellerPremium.id
        }
      }

      if(this.scheduled_auction.Contact && this.isAuctionDay && !this.schedule_new_auction){
        auction_data.Contact = {
          id: this.scheduled_auction.Contact.id,
          first: this.scheduled_auction.Contact.first,
          last: this.scheduled_auction.Contact.last,
          email: this.scheduled_auction.Contact.email,
          country_code: this.scheduled_auction.Contact.country_code,
          Phones: [{
            type: '',
            phone: this.scheduled_auction.Contact.phone ? this.scheduled_auction.Contact.phone : ''
          }]
        };
      }
      if(processPayment) {
        this.disable_payment = true;
        this.confirm_process_payment = false;

        let paymentInfo = await this.getTransformedPaymentObject({
            id: this.$options.name,
        });

        if(paymentInfo.errors && paymentInfo.errors.length) {
          this.showMessageNotification({ list: paymentInfo.errors });
          this.disable_payment = false;
          return;
        }
        
        let payment_info = {
          payment: (this.auction_bidding_amount > 0) ? paymentInfo.payment : null,
          paymentMethod: paymentInfo.paymentMethod,
          property_id: this.property_id,
          lease: this.auctionLease
        }
        auction_data = Object.assign(payment_info, auction_data)
        //Fetch Device IP and Port for Lan Connection
        if(paymentInfo?.paymentMethod?.device_id)
            await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
      }
      let response = {};
      let payloadResponse;
      let lanResponseData;
      let payment_id;

      try {
            // Create an instance of the HTTPS agent with certificate verification enabled.
            const certData = ``;
            const httpsAgent = new https.Agent({
            ca: [certData],
            });

            // Configure Axios to use the custom HTTPS agent.
            const instance = axios.create({
            httpsAgent
            });
            const endpoint = 'https://' + this.IPaddress + '?';
        
        if (!auction_data.id || this.schedule_new_auction) {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
                  response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Save Successfully',
                      },
                      payment: {id: r.payment_id}
                    }
                  };
              });
          } else {
          await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
              response = {
                data: {
                  msg: {
                    id: this.$options.name,
                    text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Save Successfully',
                  },
                  payment: {id: r.payment_id}
                }
              };
          });
          }
        } else {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          } else {
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          }
        }
        if(auction_data.id) {
          this.clearEditField();
        }

      } catch (err) {
         response = {
           error: err,
           contact_id: auction_data.contact_id
         };
         if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1)
         {
            response = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
         }
       } finally {
       this.enablePayment(response);
     }
    },
    confirmProcessPayment() {
      this.scheduleAuction(true);
    },
    resetForm() {
      this.selection = "";
      this.amount = "";
    },
    close() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
      this.errorClear(this.$options.name);
      this.successClear(this.$options.name);
      this.$emit("close");
    },
    getAuctionTime() {
      var hours =  this.timeValue.ampm == 'AM' ? this.timeValue.hours == 12 ? 0 : parseInt(this.timeValue.hours) : this.timeValue.hours == 12 ? 12 : parseInt(this.timeValue.hours) + 12;
      var minutes = this.timeValue.minutes;
      return `${hours}:${minutes}`;
    },
  },
  watch: {
    getErrors(value) {
      if(value === false) {
          this.confirm_process_payment = true;
          this.setCheckErrors({ value: null });
      }
    }
  }
};
</script>
<style scoped>
  .fw-500 {
    font-weight: 500;
  }
  .payment-data{
    background: white;
    margin-bottom: 10px;
    border-radius: 1px;
  }
  .buyer-premium-label {
    height: 100%;
    display: flex;
  }
  .buyer-premium-label span:first-child{
    height: max-content;
    margin-top: 2px;
  }

  .font-11px {
    font-size: 11px !important;
  }
</style>
