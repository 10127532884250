<template>
  <div>
    <v-container class="payment-method-container pb-4">
      <v-row>
        <v-col><strong class="text-capitalize">{{ payment_type }} Payment</strong></v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="ma-0 pa-0"><strong>Transaction Details</strong></v-col>
      </v-row>
      <v-row class="pa-0 ma-0" v-if="payment_type && payment_type.toLowerCase() === 'cash'">
        <v-col class="pa-0 ma-0" :cols="(paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0) ? 9 : 12">
          <small class="small-label" :class="{'text-error' : errors.first('amountTendered') }">Cash Amount Tendered*</small>
          <v-text-field
            key="amountTendered"
            class="cash-input-filed"
            :class="{'custom-field-error' : errors.first('amountTendered') }"
            hide-details
            :value="getPrecisiedVal"
            v-validate="`required|min_value:${paymentData.totalNewPaymentPaying}`"
            data-vv-name="amountTendered"
            data-vv-as="Cash Amount Tendered"
            :error-messages="errors.collect('amountTendered')"
            prepend-inner-icon="mdi-currency-usd"
            id="amountTendered"
            name="amountTendered"
            @input="handleAmountTendered('amountTendered', $event)"
          >
          </v-text-field>
        </v-col>
        <v-col v-show="paymentData.amountTendered - paymentData.totalNewPaymentPaying > 0" class="pa-0 ma-0 pl-4" cols="3">
          <div class="text-caption">Change Due</div>
          <div>{{ (paymentData.amountTendered - paymentData.totalNewPaymentPaying) | formatMoney }}</div>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0 pt-2">
          <v-text-field
            key="ref_name"
            :class="{'custom-field-error' : errors.first('ref_name')}"
            hide-details
            :value= "payment.ref_name"
            v-validate="'required'"
            data-vv-name="ref_name"
            data-vv-as="Reference Name"
            :error-messages="errors.collect('ref_name')"
            label="Reference Name*"
            id="cash-ref-name"
            name="cash-ref-name"
            autocomplete="cc-cash-ref-name"
            @input="handleInputUpdate('payment', 'ref_name', $event)"
            >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" v-if="payment_type && payment_type.toLowerCase() === 'check'">
        <v-col class="pa-0 ma-0 pt-2">
          <v-text-field
            key="number"
            :class="{'custom-field-error' : errors.first('number')}"
            hide-details
            :value="payment.number"
            v-validate="payment_type.toLowerCase() === 'check'? 'required': ''"
            data-vv-name="number"
            data-vv-as="Check Number"
            :error-messages="errors.collect('number')"
            label="Check Number*"
            id="cash-number"
            name="cash-number"
            autocomplete="cc-cash-number"
            @input="handleInputUpdate('payment', 'number', $event)"
            >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col class="pa-0 ma-0 pt-2">
          <v-textarea
            :class="{'custom-field-error' : errors.first('notes')}"
            rows="2"
            hide-details
            :value="payment.notes"
            data-vv-name="notes"
            :error-messages="errors.collect('notes')"
            label="Notes"
            id="cash-notes"
            name="cash-notes"
            autocomplete="cc-cash-notes"
            @input="handleInputUpdate('payment', 'notes', $event)"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script type="text/babel">

    import PaymentMethodBase from './PaymentMethodBase.vue';
    import { EventBus } from '../../../EventBus';
    import { mapGetters, mapMutations } from "vuex";

    export default {
        extends: PaymentMethodBase,
        data() {
            return {
            }
        },
        components:{},
        computed:{
          ...mapGetters({
            paymentConfig: 'paymentsStore/getPaymentSourceConfig',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_method: 'paymentsStore/getPaymentMethod',
            contact: 'paymentsStore/getContact',
            payment: 'paymentsStore/getPayment',
            getErrors: 'paymentsStore/getErrors'
        }),
          getPrecisiedVal() {
            return this.paymentData.amountTendered ? parseFloat(this.paymentData.amountTendered).toFixed(2) : this.paymentData.amountTendered;
          }
        },
        created(){
        },
        destroyed() {
        },
        props: ['payment_type'],
        methods:{
          ...mapMutations({
            setCheckErrors: 'paymentsStore/setCheckErrors',
            setPaymentInformation: 'paymentsStore/setPaymentInformation',
            setPayment: 'paymentsStore/setPayment',
            setPaymentMethod: 'paymentsStore/setPaymentMethod',
          }),

          handleAmountTendered : _.debounce(async function(property_name, property_value) {
            property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
            this.setPaymentInformation({property: property_name, propertyValue: property_value});
          }, 1000),

          handleInputUpdate : _.debounce(async function(obj ,property_name, property_value) {
            if(obj === 'payment_information'){
              if(property_name === 'amountTendered') property_value = Number(parseFloat(property_value).toFixed(2)) || 0;
              this.setPaymentInformation({property: property_name, propertyValue: property_value});
            }
            if(obj === 'payment'){
              this.setPayment({property : property_name, propertyValue: property_value});
            }
          }, 200),

          validateFields(){
            return this.$validator.validateAll();
          }
        },
        watch:{
        }


    }
</script>
<style scoped>

.text-error {
  color: red !important;
}

.small-label {
  font-size: 11px; 
  color: grey;
}

.cash-input-filed {
  padding-top: 0px;
  margin-top: -5px;;
}

</style>
