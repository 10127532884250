export default [
	{
		name: 'Tenants',
		path: '/tenants',
		component: () => import('../components/tenants/Index.vue'),
		meta: {
			requiresAuth: true,
			hasAccess: ['admin'],
      		layout: 'master',
			re_fetch:true
		},
		children: [
			{
			  path: '/national-accounts', // Nested route for National Accounts
			  name: 'NationalAccounts',
			  component:  () => import('../components/tenants/NationalAccountReport.vue'),
			  meta: {
				requiresAuth: true,
				hasAccess: ['admin'],
				layout: 'master',
				re_fetch:true
			  },
			}
		  ]
	},
	{
		path: '/my-account',
		component: () => import('../components/tenants/View.vue'),
		meta: {
			requiresAuth: true,
			layout: 'unauthenticated',
			hasAccess: ['tenant']
		}
	}
]
