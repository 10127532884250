<template>
  <span id="component-assets-send-receipt">
    <div class="pa-5 ma-0">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
        status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
        :message="successGet($options.name)" status="success"></status>
      <div class="pb-4 hb-font-header-3">
        How do you want to send the Invoice?
      </div>
      <hb-checkbox v-model="textCheck" label="Text" class="mb-2" @change="clearError('Text')">
      </hb-checkbox>
      <hb-text-field  :readonly="!textCheck" hide-details placeholder="Enter Phone" v-model="formattedPhone" v-validate="'required'"
        :error="errors.has('receipt.sms')" data-vv-name="sms" data-vv-as="Phone"
        data-vv-scope="receipt"></hb-text-field>
      <hb-checkbox v-model="emailCheck" label="Email" class="mt-4 mb-1" @change="clearError('Email')">
      </hb-checkbox>
      <hb-text-field :readonly="!emailCheck" id="input-assets-send-receipt-email" name="email" placeholder="Enter Email" v-model="invoice.email"
        v-validate="{ required: true, max: 45, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
        data-vv-name="email" data-vv-as="Email" :error="errors.has('receipt.email')" style="margin-top: 6px;"
        data-vv-scope="receipt"></hb-text-field>
    </div>
    <hb-bottom-action-bar @close="closeModal">
      <template v-slot:right-actions>
        <hb-btn color="secondary" @click="takeAction()" :disabled="!emailCheck && !textCheck" :loading="loading">Send
          Invoice</hb-btn>
      </template>
    </hb-bottom-action-bar>
  </span>
</template>

<script type="text/babel">
import api from "../../assets/api.js";
import { mapGetters } from "vuex";
import { EventBus } from "../../EventBus.js";
import { notificationMixin } from '../../mixins/notificationMixin.js';
import { isValidPhoneNumber } from 'libphonenumber-js';

export default {
  name: "SendInvoice",
  expose: ['closeModal', 'fetchDataOnEvent'],
  mixins: [notificationMixin],
  data() {
    return {
      lease: '',
      attachments: 'null',
      payment: {},
      invoice: {
        phone: null,
        email: null
      },
      emailCheck: false,
      textCheck: false,
      loading: false,
      contact: '',
      property: '',
      invoiceNumber: '',
    };
  },
  props: ["payment_id"],
  computed: {
    ...mapGetters({
      payments: "onBoardingStore/payments"
    }),
    formattedPhone: {
      // Getter
      get() {
        return this.invoice.phone
          ? this.$options.filters.formatPhone(this.invoice.phone)
          : null;
      },
      // Setter
      set(value) {
        // Set the phone value after performing some logic, e.g., stripping non-numeric characters
        this.invoice.phone = value.replace(/\D/g, '');
      }
    }
  },
  async created() {
    this.fetchDataOnEvent()
  },
  methods: {
    clearError(method) {
      if (method === 'Email') {
        this.$validator.reset('receipt.email');
      } if (method === 'Text') {
        this.$validator.reset('receipt.sms');
      }
    },
    async fetchDataOnEvent() {
      await this.fetchData();
      this.invoice.email = (this.payment.Contact && this.payment.Contact.email) ? this.payment.Contact.email : '';
      this.invoice.phone = (this.payment.Contact && this.payment.Contact.Phones && this.payment.Contact.Phones.length) ? this.payment.Contact.Phones[0].phone : '';
    },
    closeModal() {
      this.$emit('close')
      this.resetChecks()
      this.errorClear(this.$options.name);
      this.errors.clear();
      this.$validator.reset();
      this.$validator.errors.clear();
    },
    resetChecks() {
      this.emailCheck = false
      this.textCheck = false
    },
    async takeAction() {
      this.loading = true
      const promises = [];
      if (this.emailCheck) {
        promises.push(this.sendEmail().then(() => ({ type: 'email', status: 'fulfilled' })).catch(error => ({ type: 'email', status: 'rejected', error })));
      }
      if (this.textCheck) {
        promises.push(this.sendText().then(() => ({ type: 'text', status: 'fulfilled' })).catch(error => ({ type: 'text', status: 'rejected', error })));
      }
      try {
        const results = await Promise.allSettled(promises);
        console.log(results, "results");
        results.forEach(result => {
          if (result.value.status === 'fulfilled') {
            if (result.value.type === 'email') {
              this.emailCheck = false
            } else if (result.value.type === 'text') {
              this.textCheck = false
            }
          }
        });
        // Emit 'close' only if both functions succeed
        const successTypes = results
          .filter(result => result.value.status === 'fulfilled')
          .map(result => result.value.type);
        // Construct the dynamic message
        let description;
        if (successTypes.length === 2) {
          description = 'The invoice has been sent via both email and text.';
        } else if (successTypes.length === 1) {
          description = `The invoice has been sent via ${successTypes[0]}.`;
        }
        if (successTypes.length > 0) {
          this.loading = false
          this.showMessageNotification({
            type: 'success',
            description: description
          });
        }
        // Emit 'close' only if both email and SMS succeeded
        const allSuccess = results.every(result => result.value.status === 'fulfilled');
        if (allSuccess) {
          this.loading = false
          this.$emit('close');
          this.resetChecks()
        }
      } catch (error) {
        this.loading = false
        console.error("An error occurred:", error);
      }
    },
    async generateInvoice(invoice) {
      console.log(invoice);
      var data = {
        type: "invoice",
        format: "pdf",
        request_id: invoice,
      };

      let response = await api.post(this, api.REPORTS + "download-pdf", data);
      const arr = new Uint8Array(response.data);
      var blob = new Blob([arr], { type: "application/pdf" });
      var file = new File(
        [blob],
        `Invoice #${this.invoiceNumber}.pdf`,
        { type: blob.type }
      );
      return file;
    },
    async fetchData() {
      let payment_id = this.payment_id || null;
      if (!payment_id) return;
      let r = await api.get(this, api.INVOICES + this.payment_id);
      console.log(r);
      this.invoice.email = r.invoice.Lease.Tenants[0].Contact.email;
      this.contact = r.invoice.Contact;
      this.property = r.invoice.Property;
      this.payment = r.invoice;
      this.invoiceNumber = r.invoice.number
      this.lease = r.invoice.Lease.Unit.number
    },
    async sendEmail() {
      let doc = await this.generateInvoice(this.payment_id);
      this.attachments = doc;
      console.log(this.attachments, "this.attachments");
      let status2 = await this.$validator.validate("receipt.email");
      if (!status2) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.loading = false
        throw new Error('Validation failed'); // Explicitly throw an error to reject the promise
      }

      let payment_id = this.payment_id || null;
      if (!payment_id) {
        this.showMessageNotification({ type: 'error', description: 'There is no invoice id associated with this payment. Invoice cannot be sent.' });
        this.loading = false
        throw new Error('No payment ID'); // Explicitly throw an error
      }
      console.log(this.lease, "this.invoice.Lease.Unit.id");
      var data = {
        contact_id: this.payment.contact_id,
        message: `<p>Hey ${this.contact.first} ${this.contact.last},</p><p>Hope this finds you well! We've just sent over the invoice you've been waiting for. Please find it attached to this email.</p><p>If you have any questions or need further info, feel free to reach out.</p><p>Thank you for your prompt attention to this matter.</p><p>Best regards,</p><p>${this.property.name}<br>${this.property.Address.address ? this.property.Address.address : ""}<br>${this.property.Address.city ? this.property.Address.city : ""}<br>${this.property.Address.region ? this.property.Address.region : ""}</p><p><br></p>`,
        property_id: this.property.id,
        email: this.invoice.email,
        space: this.lease,
        subject: `Invoice Attached - Invoice #${this.invoiceNumber}`,
        browser_time: this.$options.filters.formatDateTimeCustom(this.payment.date, 'MMM DD, YYYY @ h:mma'),

      };
      //this.attachments=doc
      try {

        let File = []
        File.push(this.attachments)
        let body = {
          document_type: this.type,
          document_type_id: this.document_type_id
        }
        let fileUploadResponse = await api.postFile(this, api.CONTACTS + this.payment.contact_id + '/upload', body, File);
        data.upload_id = fileUploadResponse.uploads.id
        data.attachments = File
        data.attachments[0].content_type = fileUploadResponse.uploads.mimetype
        // return response
        let response = await api.post(this, api.INVOICES + this.payment_id + "/email", data);
        if (response && response.msg) {
          // this.showMessageNotification({ type: 'success', description: response.msg });
          return true; // Indicate success
        } else {
          this.loading = false
          throw new Error('No response message'); // Throw error for missing response
        }
      } catch (err) {
        let r = { error: err };
        this.loading = false
        this.errorSet(this.$options.name, 'Email could not be sent.');
        EventBus.$emit("paymentReader", r);
        throw err; // Throw the error to reject the promise
      }

    },
    async sendText() {
      let doc = await this.generateInvoice(this.payment_id);
      this.attachments = doc;
      let status1 = await this.$validator.validate("receipt.sms");
      if (!status1) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.loading = false
        throw new Error('Validation failed'); // Explicitly throw an error to reject the promise
      }

      var checkIfPhoneNumberValid = isValidPhoneNumber('+' + this.invoice.phone.replace(/[^0-9]/g, ""));
      if (!checkIfPhoneNumberValid) {
        // const field = this.$validator.fields.find({ name: 'sms' });
        // field.setFlags({ dirty: true });
        this.$validator.errors.add({
          id: '1', // Unique ID for the error
          vmId: this._uid, // Vue component instance ID
          field: 'sms', // The name of the field
          msg: 'Invalid phone number, correct it before submitting.', // Error message
          rule: 'phone', // Custom validation rule, like 'email' for email
          scope: 'receipt' // Scope, if needed (e.g., if the input is inside a scoped field group)
        });
        this.showMessageNotification({ type: 'error', description: 'Invalid phone number, correct it before submitting.', list: this.errors.items });
        this.loading = false
        throw new Error('Validation failed'); // Explicitly throw an error to reject the promise
      }

      let payment_id = this.payment_id || null;
      if (!payment_id) {
        this.showMessageNotification({ type: 'error', description: 'There is no invoice id associated with this payment. Invoice cannot be sent.' });
        this.loading = false
        throw new Error('No payment ID'); // Explicitly throw an error
      }
      console.log(this.lease, "this.invoice.Lease.Unit.id");
      var data = {
        contact_id: this.payment.contact_id,
        message: `Hey ${this.contact.first} ${this.contact.last},Hope this finds you well! We've just sent over the invoice you've been waiting for. Please find the link in the SMS. If you have any questions or need further info, feel free to reach out.Thank you for your prompt attention to this matter. Best regards \r\n${this.property.name}\r\n${this.property.Address.address ? this.property.Address.address : ""}\r\n${this.property.Address.city ? this.property.Address.city : ""}\r\n${this.property.Address.region ? this.property.Address.region : ""}`,
        property_id: this.property.id,
        to_phone: this.invoice.phone,
        space: this.lease,
        subject: `Invoice Attached - Invoice #${this.invoiceNumber}`,
        browser_time: this.$options.filters.formatDateTimeCustom(this.payment.date, 'MMM DD, YYYY @ h:mma'),

      };
      // this.attachments=doc
      try {

        let File = []
        File.push(this.attachments)
        let body = {
          document_type: this.type,
          document_type_id: this.document_type_id
        }
        let fileUploadResponse = await api.postFile(this, api.CONTACTS + this.payment.contact_id + '/upload', body, File);
        data.upload_id = fileUploadResponse.uploads.id
        data.attachments = File
        data.attachments[0].content_type = fileUploadResponse.uploads.mimetype
        // return response
        let response = await api.post(this, api.INVOICES + this.payment_id + "/sms", data);
        if (response && response.msg) {
          // this.showMessageNotification({ type: 'success', description: response.msg });
          return true; // Indicate success
        } else {
          this.loading = false
          throw new Error('No response message'); // Throw error for missing response
        }
      } catch (err) {
        let r = { error: err };
        this.loading = false
        this.errorSet(this.$options.name, 'Text message could not be sent.');
        EventBus.$emit("paymentReader", r);
        throw err; // Throw the error to reject the promise
      }

    }
  },
};
</script>

<style scoped></style>